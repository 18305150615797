import React, { Component } from 'react';
import '../data/global.js';//global.API+
import {youtube_links,expertVideoSettings} from '../data/localData';//global.API+
import Slider from 'react-slick';


  export const faq1 =[
  {
    id:1,
    question:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
  },
  {
    id:2,
    question:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
  },
  {
    id:3,
    question:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
  },
  {
   id:4,
   question:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    answer:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
  },
 
 ];
class Videos extends Component {
constructor(props){
		super(props);
		this.state = {
		  error: null,
	      isLoaded: false,
	      hover:false,
	      faq: [],
	      modalShow:false,
	      question:'',
	      answer:'',
	      blog:[],
	      currentIndex:''
	     
	      
		};
		// this.onMouseOver = this.onMouseOver.bind(this);
	}

	componentDidMount(){
		this.setState({
			isLoaded:true
		});
		fetch(global.API+'api/v1/get_faq')
		.then(res=> res.json())
		.then(
			(result)=>{
			// console.log(result.data.faq,"FAQ");
			
				this.setState({

					faq:result.data.faq,
					
				});
			//console.log(tmpSigal,'tmpSigal');
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)

		fetch(global.API+'blog/wp-json/wp/v2/posts?per_page=3')
		.then(res=> res.json())
		.then(
			(data)=>{
		//	 console.log(data,"Blog");
			
				this.setState({

					blog:data,
					isLoaded:false
					
				});
		//	console.log(data.id,'DATAID');
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)


	}
	 handleSort(qa){
		this.setState({
			modalShow: true,
			question:qa.question,
			answer:qa.answer
		});
	}
	showImage(index){
		this.setState({
			hover: true,
			currentIndex:index
		});
	}
	hideImage(index){
		this.setState({
			hover: false,
			currentIndex:index
		});
	}
	render() {

		return (
			<div>
				<section className="hero-section ssg-page expert-videos" id="Video">

				<div className="container">
					
					<div id="top-section" className="our-exports-videos">
						<div className="video-recent d-md-flex justify-content-md-center align-items-md-center">
							<div className="content-title">
								<h1>Our <br/>Experts <br/>Videos</h1>
							</div>		
							<div className="ml-md-2">
							 <iframe className='videosIframelg' id="bigscreen" width="500" height="300" src={youtube_links[0].url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							 {/*<div id="loadingMessage">Loading...</div>*/}
							</div>

						</div>
						<div id="" className="video-latest-section">
							<h5> Watch Our Latest Videos <span><img src="assets/images/next.png" alt="Vieos Next" /></span></h5>
							<div className="expertVideo slider">
						{/*	<Slider {...expertVideoSettings} className='expertVideo slider'>*/}
								{
									youtube_links.map(items =>(
										 <div key={items.id} className="vidSlider" data-toggle="modal">
										  <iframe width="180" height="120" src={items.url} ></iframe> 
										  </div>
										))
								}
					{/*	</Slider>		*/} 
								{/*  <div className="vidSlider" data-toggle="modal">
								  <iframe width="180" height="120" src="https://www.youtube.com/embed/szloHAXJSN4" ></iframe> 
								  </div>
								  <div className="vidSlider" data-toggle="modal">
								    <iframe width="180" height="120" src="https://www.youtube.com/embed/szloHAXJSN4" ></iframe> 
								  </div>
								   <div className="vidSlider" data-toggle="modal">
									    <iframe width="180" height="120" src="https://www.youtube.com/embed/szloHAXJSN4" ></iframe> 
								  </div>
								   <div className="vidSlider" data-toggle="modal">
								     <iframe width="180" height="120" src="https://www.youtube.com/embed/szloHAXJSN4" ></iframe> 
								  </div>*/}
								 
							</div>
						</div>
						<div className="view-more">
									<button className="view-more-btn"><a href='https://youtube.com/cryptotrade1' target="_blank">View More <img src="assets/images/arrow.png" className="arrow-see-more" /></a></button>
									
						</div>
					</div>
		    
				</div>
			</section>
			</div>
		);
	}
}
export default Videos;