import React, { Component } from 'react';
import {copytrade_slider, copytradeSliderSettings} from '../data/localData';
import Slider from 'react-slick';
class CopytradePerfomance extends Component {

	render() {
		
		return (
			<div>
				<section className="hero-section ssg-page" id="CopytradePerformance">

					<div className="container">
	
					<div id="" className="perfomance-section">
						<h2> Copytrade Performance</h2>

						<Slider {...copytradeSliderSettings} className='regular slider'>
							{
								copytrade_slider.map((items,index)=>(
									  <a key={items.id} className="imgSlider seturlr" id={items.dataTarget} data-toggle="modal" data-target={items.dataTarget}>
									  	
										  	<img src={items.imgUrl} alt={items.title} className="imgSliderTrade" />
										  	<h4>{items.title}</h4>
										
									  </a>
								))

							}
						</Slider>
					
						</div>
					</div>
				</section>

			</div>
		);
	}
}
export  default CopytradePerfomance;
