import React, { Component } from 'react';
import { Form, Button } from "react-bootstrap";
import {Redirect} from 'react-router-dom';
import PrivateHeader from './PrivateHeader';
//import '../assets/css/styles.css';
import jQuery from 'jquery';
import '../data/global.js';//global.API+

class ticketDetail extends Component {
	constructor(props){
		super(props);
		this.state = {
				error:'',
			islogout: false,
			success:'',
			isLoaded:false,
			getTicket:[],
			message:'',
			iscTLoaded:false,
			ticketDetails:'',
			comment:'',
			ticket_id:'',
			getComment:[],
			title:'',
			location:'',
			description:'',
			created_at:''
		}
		
		
		 this.get_ticket_list = this.get_ticket_list.bind(this);
		 this.signOut = this.signOut.bind(this);
		 this.get_ticket_details = this.get_ticket_details.bind(this);
		 this.submitComment = this.submitComment.bind(this);
	}

	componentDidMount(){

		this.jQueryFunction();
		jQuery('.commentSubmit, .ticket_name').click(function(){
			 jQuery(".comment-body").stop().animate({ scrollTop: jQuery(".comment-body")[0].scrollHeight}, 1000);
			jQuery("html,body").scrollTop(0); 
		});
		this.get_ticket_list();
		this.setState({
			ticket_id:this.props.match.params.id
		});
		
		this.get_ticket_details(this.props.match.params.id,'support');
 	}			
 		componentDidUpdate(){
 		
		
 		}

 		jQueryFunction(){
 			 jQuery(".comment-body").stop().animate({ scrollTop: jQuery(".comment-body")[0].scrollHeight}, 1000);
		     jQuery("html,body").scrollTop(0); 
 		}

 	signOut = () => {
	    localStorage.clear();
	    this.setState({      islogout: true    });
 	};


 	get_ticket_list(){
 		this.setState({    isLoaded: true   });
 		fetch(global.API+'api/v1/support/get_ticket',{method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ "user_id" : localStorage.getItem('userIdTokan') })})
		.then(res=> res.json())
		.then(
			(result)=>{
			// console.log(result.data.ticket,"getTicket");

				this.setState({
					getTicket:result.data.ticket,
					isLoaded:false
				});
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
 	}

 	get_ticket_details(ticket_id,screen){
 		console.log(ticket_id,"TID");
 		if(screen=='details'){
 			this.props.history.push("/ticketDetail/"+ticket_id);	
 		}
 		
 		this.setState({    isLoaded: true   });
 		fetch(global.API+'api/v1/support/ticket/details',{method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ "user_id" : localStorage.getItem('userIdTokan'),
        "ticket_id": ticket_id})})
		.then(res=> res.json())
		.then(
			(result)=>{
			  console.log(result.data.ticket,"getTicketDetail");
				this.setState({
					ticket_id:result.data.ticket.id,
					title:result.data.ticket.title,
					location:result.data.ticket.location,
					description:result.data.ticket.description,
					created_at:result.data.ticket.created_at,
					getComment:result.data.comment,
					isLoaded:false
				});
				this.jQueryFunction();
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)

 	}

	enterkeyHandler=(event)=> {
        if (event.keyCode === 13) {
        	console.log("ENTER KEY PRESS");
        	    event.preventDefault();
            this.submitComment();
            this.jQueryFunction();
        }
    }

 	submitComment(){
 		fetch(global.API+'api/v1/support/ticket/send_comment',{method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ "user_id" : localStorage.getItem('userIdTokan'),
        "ticket_id": this.state.ticket_id,
    	"comment" :  this.state.comment})})
		.then(res=> res.json())
		.then(
			(result)=>{
			  console.log(result.data,"SUBMIT COMMENTs");
				this.setState({
					// title:result.data.ticket.title,
					// location:result.data.ticket.location,
					// description:result.data.ticket.description,
					 comment:'',
					 getComment:result.data,
					isLoaded:false
				});
				  console.log(this.state.getComment,"SUBMIT getComment");
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
 	}

	render() {
		 if (this.state.islogout || localStorage.getItem("status")!=='1' ) {
	      return <Redirect to="/login" />;
	    }
		return (
			<div>
			<PrivateHeader logout={this.signOut.bind(this)}/>
			<section className="support-section ssg-page">
				<div className="container">
				 <div id="top-section" className="support-home">
					<div className="row d-flex justify-content-center">
						<div className='col-md-12'>
							<div className='row support'>
							
							{
							this.state.iscTLoaded?<div className="loader-fix"><div className="loader"></div></div> :
								<div className='col-md-7 p-0'>
								
										<div className="login-box">
										<div className='ticketDetail-header'>
											<h2>{this.state.title}</h2>
											<div className='ticket_info'>Created on: {this.state.created_at} - location: {this.state.location}</div>
										</div>
											<div className='description-section'>{this.state.description}</div>
											<div className='comment-section'>	
												<div className='comment-body'>
													<div>
													{
															this.state.getComment.map((items,index) =>(
															<div key={items.id} className='chatPanel'>
																<div className='userChat'>
																	<span className='user_info'><p className='user-comment'>{items.comment}</p><p className='user_sub_info'>{localStorage.getItem('name')} {items.created_at}</p></span>
																	<span className='userIcon'><i className="fa fa-user-circle" aria-hidden="true"></i></span>
																</div>
															</div>
																))
													}
														
													</div>
												</div>
											
													
											
												<Form className='ticket-detail-Form'>
												<div className='submit-section'>
													  <Form.Group className='commenttext' controlId="formBasicchat" >
													  
													    <Form.Control type="text" className='inputTitle ticketDetailInput'  value={this.state.comment}  onChange={e => this.setState({ comment: e.target.value })}  onKeyDown={(e) => this.enterkeyHandler(e) }  placeholder="Write your comment here..." />
													  
													   
													  </Form.Group>
													  <Button className='submitButton commentSubmit' onClick={this.submitComment} on variant="primary" type="button">
													    Send
													  </Button>
												  </div>
												</Form>
											 </div>
										</div>
								</div>
							}
						
								<div className='col-md-5 p-0'>
									<div className='ticket-box'>
										<div className='title-ticket'>Your Ticket</div>
											<div className='list-ticket'>
											{this.state.isLoaded? 	<div className="loader-fix"><div className="loader"></div></div> : 
												<ol className='list-of-ticket'>
												{
													this.state.getTicket.map((items,index) =>(
														<li className='ticket_name' key={items.id} onClick={this.get_ticket_details.bind(this, items.id, 'details')}>
															{items.title}
														</li>
													))
												}
											    </ol>
											}
											</div>
									</div>
									
								</div>
							</div>
						
						</div>
					</div>
				</div>
			</div>
			</section>
		</div>
	
		);
	}
}


export default ticketDetail;