import React, { Component } from 'react';
import SupportHome from './SupportHome';
import PrivateHeader from './PrivateHeader';
import { Form, Button } from "react-bootstrap";
import jQuery from 'jquery';
import {Redirect} from 'react-router-dom';
//import 'assets/js/ssg.js';
import '../data/global.js';



class Login extends Component {
	constructor(props){
		super(props);
		this.state = {
			accountNo:'',
			error:'',
			isLoaded:false,
			islogged: false,
			success:'',
			userIdTokan:''

		}
		
		// this.openTerms = this.openTerms.bind(this);
	}		
	
	componentDidMount(){
	
		jQuery(window).on('popstate', function(event) {
		  //  alert("pop");
		   	 window.location.reload(false);
		});
 	}

	updateState = (tokan) => {
    this.setState(
      (state, props) => {
        return { islogged: true,userIdTokan:tokan};
      });
	};

	enterkeyHandler=(event)=> {
        if (event.keyCode === 13) {
        	 event.preventDefault();
              this.onSubmit();
        }
    }

   onSubmit = () => {
   	this.setState({
			isLoaded:true
		});
   	fetch(global.API+'api/v1/support/login',{method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "account_number" : this.state.accountNo  })
 	   })
		.then(res=> res.json())
		.then(
			(getData)=>{
			  console.log(getData,"getData");
				if(getData.status==404){
					 console.log(getData.message,"getDatamessage");
					this.setState({	error:getData.message,success:'',isLoaded:false});
				}

				if(getData.status==200 && getData.message=='Success'){
				
					localStorage.setItem("status", getData.data.status);
					localStorage.setItem("name", getData.data.name);
					localStorage.setItem("email", getData.data.email);
					localStorage.setItem("userIdTokan", getData.data.id);
					//ssg.revealRight();
					window.sessionStorage.setItem("userIdTokan", getData.data.id);
					this.setState({	isLoaded:false,
						islogged:true,
						error:''
					});
					this.updateState(getData.data.id);
					this.props.history.push("/Support");

				}
				
				
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
     console.log(this.state.islogged,"islogged");
     console.log(this.state.error,"test");
  };
	render() {
		
		if (localStorage.getItem("status")=='1' && localStorage.getItem("userIdTokan")!=='') {
	      return <Redirect to="/Support" />;
	    }

		return (
			<div>
				<PrivateHeader/>
				<section className="hero-section support-section login-section">

					<div className="container">
						<div id="top-section" className="support-block">
						{this.state.isLoaded? 	<div className="loader-fix"><div className="loader"></div></div> : 																		
							<div className="support">
												
								<div className='login-error'>{this.state.error}</div>
								<div className='login-success'>{this.state.success}</div>
								<div className="login-box">
								<h2>Login to the support portal</h2>
										<Form className='login-Form'>
										  <Form.Group controlId="formBasicEmail">
										    <Form.Label className='loginLabel'>Enter the details below</Form.Label>
										    <input   value={this.state.accountNo} onChange={e => this.setState({ accountNo: e.target.value })} onKeyDown={(e) => this.enterkeyHandler(e) } type="text" className='inputAccount form-control' name='account' placeholder="Your Account Number" />
										   
										  </Form.Group>
										  <Button className='loginButton' variant="primary" type="button"  onClick={this.onSubmit}>
										    Login
										  </Button>
										</Form>
									 
								</div>
							</div>
						}	

						</div>
					</div>
					
					

				</section>

			</div>
	);
	}
}

export default Login;
