import React, { Component } from 'react';
import SubModal from './SubModal';
import CountUp from 'react-countup';
import { Modal, Button, Accordion, Card, Header, Form } from "react-bootstrap";
import {social_media} from '../data/localData';//global.API+
//import jQuery from 'jquery';

class Homescreen extends Component {
	constructor(props) {
    super(props);
    this.state = {
	  imodalShow:false,
	  modalShow:false,
	  isOpen: false,
	  url:''
    };
     this.iframeShow = this.iframeShow.bind(this);
    
  }

	
	  iframeShow(){
		this.setState({
			imodalShow: true,
			url:'https://xn--r1a.website/s/cctrade1',
		});
	 }
/*
	componentDidMount() {
		if(window.location.hash == '#dynamicPopupInvestment'){  
			this.iframeShow();
		}
	}*/
	

  	componentDidMount() {
		if(window.location.hash == '#sussesspayment'){  
			this.setState({
		        isOpent: true
		    });
		}
		if(window.location.hash == '#cancelpayment'){  
			this.setState({
		        isOpen: true
		    });
		}
		if(window.location.hash == '#telegram'){  
			this.iframeShow();
		}
	}

	 openModal = () => this.setState({ isOpen: true });
 	 closeModal = () => this.setState({ isOpen: false });
 	 closeModalt = () => this.setState({ isOpent: false });
	render() {
		return (
			<div>
				<section className="hero-section ssg-page home-section" id="home">
					<div className="container">
						
						<div id="top-section">
							<div className="hero-block">
								<div className="content-title">
									<h1>100% <br/>Free Signal</h1>
								</div>	
								<div className="see-more">
											<button onClick={this.iframeShow} className="see-more-btn telegram-show">Telegram <img src="assets/images/arrow.png" className="arrow-see-more" /></button>
									
										</div>
								<div className='row telegram-countup'>
									
									<div className='col'>
										<div className="total-followers light-count">
												<div className='title-counter'>Total Followers</div>
												<div className='counter followers'><CountUp start={100000} end={133781} /></div>								
										</div>
									</div>
									<div className='col'>
										<div className="total-signals light-count">
												<div className='title-counter'>Total Signals</div>
												<div className='counter signsls'><CountUp start={0} end={300} />+</div>										
										</div>
									</div>
									<div className='col'>
										<div className="total-accuracy light-count">
												<div className='title-counter'>Total Accuracy</div>
												<div className='counter accuracy'><CountUp start={-10} end={90} />%</div>										
										</div>
									</div>
									<div className='col'>
										<div className="total-return light-count">
											<div className='title-counter'>Total Return</div>
											<div className='counter return'><CountUp start={1000} end={1000} />% to <CountUp start={10000} end={10000} />%</div>									
										</div>
									</div>
									

								</div>
								
							</div>
							<div className="row footer-hero-row slide-out-up">
								<div className="col-md-4 mission light">
									<h2>Mission</h2>
									<p>
										Our mission is to become customer-centric company with one statement of protecting the financial standards of all the surrounded entities. To be the socially responsible as well by providing all possible means to society to create a better tomorrow.
									</p>
									
								</div>
								<div className="col-md-4  aim">
									<h2>Aim</h2>
									<p>
										Our aim is to provide the “Best-in-class” crypto services with most transparent means possible. To generate greater returns with well-defined analysis and predictions. Our sole concern is to protect the capital, minimise the losses with precise advice and maximise the returns.
									</p>
									
								</div>
							
							<div className="col-md-4  our-skills">
									<h2>Our Skills</h2>
									<p>
										What an aspiring analyst has to offer to his loyal clients is largely abstract- and creative-thinking skills. These skills are intangible and difficult for recruiters to assess. This is one reason why Our firm in finance tend to make Profits.
									</p>
									
								</div>
							
						</div>
						</div>
					</div>
			<SubModal
			
				screen='iframe'
				bodyContent={this.state.url}
				show={this.state.imodalShow}
			    onHide={() => this.setState({ imodalShow: false })}
			  />


		<Modal className='thankyou-modal error-popup' show={this.state.isOpen} onHide={this.closeModal}>
         
	      <Modal.Header>
	        <Modal.Title></Modal.Title>
	          <Button variant="link" onClick={this.closeModal} className="close close-payment-popup" data-dismiss="modal" aria-label="Close" closeButton>
	          <span aria-hidden="true" class="colse-done">Done</span>
	        </Button>
	      	</Modal.Header>
	     	 	<Modal.Body>

	     	 	<div className="Thankyou-success">
		     	 	<img src="assets/images/close-icon.gif"/>
		     	 	<h2>Cancel</h2>
		     	 	<p>Your payment has been cancelled</p>

		     	 	<div className="social-media-icons">
						<ul>
						{
							social_media.map((items,index)=>(
								<li key={items.id}>
										<a href={items.url} target="_blank">
											<img src={items.image} alt={items.name} />
										</a>
									</li>	
								))
						}
						</ul>
					</div>
	     	 	</div>
     	 		</Modal.Body>
      	 	<Modal.Footer>
      		</Modal.Footer>
	    </Modal>
			<Modal className='thankyou-modal' show={this.state.isOpent} onHide={this.closeModalt}>
		      <Modal.Header>
		        <Modal.Title></Modal.Title>
		          <Button variant="link" onClick={this.closeModalt} className="close close-payment-popup" data-dismiss="modal" aria-label="Close" closeButton>
		          <span aria-hidden="true" class="colse-done">Done</span>
		        </Button>
		      </Modal.Header>
		     	 <Modal.Body>

		     	 <div className="Thankyou-success">
		     	 	<img src="assets/images/success-icon.gif"/>
		     	 	<h2>Thank You</h2>
		     	 	<p>Your payment was successful</p>

		     	 	<div className="social-media-icons">
							<ul>
							{
								social_media.map((items,index)=>(
									<li key={items.id}>
											<a href={items.url} target="_blank">
												<img src={items.image} alt={items.name} />
											</a>
										</li>	
									))
							}
							</ul>
						</div>
		     	 </div>
		     	 	</Modal.Body>
		      	 <Modal.Footer>
		      </Modal.Footer>
	    </Modal>
				</section>
			</div>
		);
	}
}

export default Homescreen;