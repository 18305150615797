import React, { Component } from 'react';
import SubModal from './SubModal';
import Slider from 'react-slick';
import '../data/global.js';


var settingsFAQ = {
    // infinite: true,
    //  speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
};
const faq1 = [{
        id: 1,
        question: 'This first question consectetur adipiscing elit?',
        answer: 'this is first consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    },
    {
        id: 2,
        question: 'This Second question consectetur adipiscing elit?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    },
    {
        id: 3,
        question: 'This Third question consectetur adipiscing elit?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    },
    {
        id: 4,
        question: 'This fourth question consectetur adipiscing elit? ?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    },
    {
        id: 5,
        question: 'This First again question consectetur adipiscing elit?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    },
    {
        id: 6,
        question: 'This Second again question consectetur adipiscing elit? ?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
    },

];
class BlogFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            hover: false,
            faq: [],
            faq1: [],
            modalShow: false,
            question: '',
            answer: '',
            blog: [],
            currentIndex: ''


        };
        // this.onMouseOver = this.onMouseOver.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoaded: true,
            faq1: faq1
        });
        fetch(global.API + 'api/v1/get_faq')
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result.data.faq,"FAQ");

                    this.setState({

                        faq: result.data.faq,

                    });
                    //console.log(tmpSigal,'tmpSigal');
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        fetch(global.blogAPI+'wp-json/wp/v2/posts?per_page=3')
            .then(res => res.json())
            .then(
                (data) => {
                    //	 console.log(data,"Blog");

                    this.setState({

                        blog: data,
                        isLoaded: false

                    });
                    //	console.log(data.id,'DATAID');
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )


    }
    handleSort(qa) {
        this.setState({
            modalShow: true,
            question: qa.question,
            answer: qa.answer
        });
    }
    showImage(index) {
        this.setState({
            hover: true,
            currentIndex: index
        });
    }
    hideImage(index) {
        this.setState({
            hover: false,
            currentIndex: index
        });
    }

    render() {
        return (
            <div>
					<section className="hero-section blog-faq-section ssg-page" id="BlogFAQ">
									<div className="container">
										
									  <div id="top-section" className="our-blog-faq">
										
										  <div className="blog-section" >
											<h2>Blogs</h2>
											{
												this.state.isLoaded?	<div className="loader-fix"><div class="loader"></div></div>:
											
												<div className="row">
											{ this.state.blog.map((items,index)=>(
													
													<div key={items.id} className={(index==2)?"col-md-4 blog-block d-none d-sm-block":"col-md-4 col-12 blog-block"}>
														<div className="card first-card">
													 	   <img className="card-img-top" src="assets/images/cryptocurrency.png" alt="cryptocurrency image" style={{width:'100%'}} />
													   		 <a href={items.link} target="_blank" className="card-body">
													    	
													     	 <h4 className="card-title">{items.title.rendered}</h4>
														     <div className="read-more-block">
														     	<span>
														     		 
														     		<p className="card-text text-dark">Read More</p>
														     	</span> 
														     	<span>
														     		<img src="assets/images/right-arrow-color.png" alt="Read More" className="read-more-arrow" /></span>
														     </div>
													     </a>
													      	
													    </div>
													  </div>
													  ))
												}
													 
													

												</div>

										
										}	
											<div className="view-more view-more-blog">
														<button className="view-more-btn"><a href={global.blogAPI} target="_blank">View More <img src="assets/images/arrow.png" className="arrow-see-more" /></a></button>
														
											</div>
									<div className="faq-section">
									<div className='align-items-center justify-content-between search-panel'>
										<h2>FAQ</h2>
										 <input type="text" id="faqslider" className='search-faq' onkeyup="searchbyname(this)" placeholder="Search  " />
										</div>
									
											
											<div className="col-md-12">
											
												<div className='faqSlider'>
												
												{this.state.faq.map((items,index) =>(
													<div key={items.id} onClick={this.handleSort.bind(this, items)} >
												      
												      <h3 key={items.id} onMouseOver={this.showImage.bind(this,index)} onMouseLeave={this.hideImage.bind(this,index)}  className={ this.state.currentIndex==index && this.state.hover ? 'faq-box faq-1 hover_line':'faq-box faq-1' }>{items.question}<span className="faq-answer" style={{display:'none'}}>{items.answer}</span></h3>
												     
												     </div>
												))}	
										
											 </div>   											      	
											</div>
										
									</div>
												
										  </div>
										</div>
									</div>
									
						</section>	
						<SubModal

							headerContent={this.state.question}
							bodyContent={this.state.answer}
					        show={this.state.modalShow}
					        onHide={() => this.setState({ modalShow: false })}
					      />
			</div>
        );
    }
}

export default BlogFAQ;