import React from 'react';

const ModalHeader = (props) => {
  return (
    <div>
		<div className="modal-header report-popup-header">
			<div className="header-top-img"> <img src={props.headerImg} /> </div>
			<div className="header-middle">	<div className='currentMonth'>{props.month}</div> </div>
			<div className="header-livechart">
				<div className="liverchart-btn">
					 <span><img className="liveChartImg" src="assets/images/chartIcon.png" /></span>
					 <a href="javascript:;" className='openLiveChart'>Excel Performance</a>
					{/* Live Chart Status */ }
				 </div>
				<div className="openTradeData-btn" style={{display:'none'}}>
				 <span className="fa fa-long-arrow-left back-arrow"></span>
				<span> <a href="javascript:;" className='openTradeData openTradeData-back'>Back</a></span>
				 </div>
			</div>
			<div className="header-close-btn">
				<button type="button" className="close trade-popup-close" data-dismiss="modal" aria-label="Close">
				 <span aria-hidden="true" className='colse-done'>Done</span> </button>
				{/*<button type="button" className="close trade-popup-close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true"><img src="assets/images/rejected.png" /></span> </button>*/}
			</div>
		</div>
    </div>
  )
}

export default ModalHeader;