import React, { Component } from 'react';
import {perfomance_slider, perfomanceSliderSettings} from '../data/localData';
import Slider from 'react-slick';
class LivePerfomance extends Component {

	render() {
		
		return (
			<div>
				<section className="hero-section ssg-page" id="Performance">

					<div className="container">
	
					<div id="" className="perfomance-section">
						<h2> Live Performance</h2>
						{/*<div className="regular slider">
						  <div className="imgSlider" data-toggle="modal" data-target="#dynamicPopupTrading">
						  	<img src="assets/images/imgSlider1.png" alt="TRADING SIGNALS" className="imgSliderTrade" />
						  		<h4>TRADING SIGNALS</h4>
						  </div>
						  <div className="imgSlider" data-toggle="modal" data-target="#dynamicPopupInvestment">
						  	<img src="assets/images/imgSlider2.png" alt="INVESTMENT SIGNALS" className="imgSliderTrade" />
						  		<h4>INVESTMENT SIGNALS</h4>
						  </div>
						  <div className="imgSlider" data-toggle="modal" data-target="#dynamicPopupDemandChart">
						  	<img src="assets/images/imgSlider3.png" alt="LIVE DEMAND CHART" className="imgSliderTrade" />
						 	 	<h4>INVESTMENT CHART</h4>
						  </div>
						  <div className="imgSlider" data-toggle="modal" data-target="#dynamicPopupTradeChart">
						  	<img src="assets/images/imgSlider4.png" alt="LIVE TRADE CHART" className="imgSliderTrade" />
						  		<h4>LIVE TRADE CHART</h4>
						  </div>

						</div>*/}

						<Slider {...perfomanceSliderSettings} className='regular slider'>
							{
								perfomance_slider.map((items,index)=>(
									  <a key={items.id} className="imgSlider seturlr" id={items.dataTarget} data-toggle="modal" data-target={items.dataTarget}>
									  	
										  	<img src={items.imgUrl} alt={items.title} className="imgSliderTrade" />
										  	<h4>{items.title}</h4>
										
									  </a>
								))

							}
						</Slider>
					
						</div>
					</div>
				</section>

			</div>
		);
	}
}
export  default LivePerfomance;
