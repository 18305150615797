import React from 'react';

const CopytradeModalHeader = (props) => {
  return (
    <div>
		<div className="modal-header report-popup-header">
			<div className="header-top-img"> <img src={props.headerImg} /> </div>
			<div className="header-middle">	<div className='currentMonth'>Copytrade Performance</div> </div>
			
			<div className="header-close-btn">
				<button type="button" className="close trade-popup-close" data-dismiss="modal" aria-label="Close">
				 <span aria-hidden="true" className='colse-done'>Done</span> </button>
			</div>
		</div>
    </div>
  )
}

export default CopytradeModalHeader;