import React, {useState} from 'react';
import SubModal from './SubModal'
import { Modal, Button, Accordion, Card, Header, Form } from "react-bootstrap";
import '../data/global.js';//global.API+
import {social_media} from '../data/localData';//global.API+


function ContactUs(props) {
	  const curls = window.location.href;
	  const [inputs, setInputs] = useState({});

	  inputs.curls = curls;
	  const [errorMessage, setErrorMessage] = useState('');

	  const handleChange = (event) => {
	    const name = event.target.name;
	    const value = event.target.value;
	    setInputs(values => ({...values, [name]: value}))
	  }
		const saveFormData = async () => {
			try {
				console.log(inputs+'asd');
				fetch(global.API+'api/v1/post_payment',{
			      method: 'POST',
			      body: JSON.stringify(inputs)
			    })
					.then(res=> res.json())
					.then(
						(result)=>{
							if(result.status == 200) {
								
								window.open(result.url,"_self");
								setErrorMessage('');
						      setInputs({
						        currency: '', price: '' 
						      });	
							}else {
								setErrorMessage('Payment failed!');
							}	
						 
						},
						(error)=>{
							setErrorMessage('Payment failed!');
						}
					)
		  } catch (e) {
				setErrorMessage('Payment failed!'); 
		  }
	  }
	  const onSubmit = async (event) => {
	    event.preventDefault(); // Prevent default submission
	    try {
	      await saveFormData();
	    } catch (e) {
				setErrorMessage('Payment failed!');
	    }
	  }
		const currency = ['VES', 'ARS', 'DKK', 'BGN', 'ELI VIP', 'CZK', 'CNY', 'EUR', 'NZD', 'PHP', 'ZAR', 'HUF', 'COP', 'ISK', 'RUB', 'BRL', 'NGN', 'SGD', 'THB', 'CHF', 'HKD', 'IDR', 'ILS', 'INR', 'KRW', 'MXN', 'MYR', 'HRK', 'TRY', 'USD', 'GBP', 'JPY', 'PLN', 'SEK', 'NOK', 'AUD', 'CAD', 'CLP', 'CRC', 'EGP', 'GEL', 'GHS', 'KES', 'MAD', 'PEN', 'LKR', 'AED', 'UYU', 'XOF', 'XCD', 'RON'];

  return (
   
     <div>
	 <section className="contact-form" id="ContactForm">

		<div className="container">
			<div id="contact-inner" className="contact-in">
						<h2 className="text-white text-center">Payment</h2>	
				{errorMessage && (
				  <p className="error text-danger"> {errorMessage} </p>
				)}								
				<form onSubmit={onSubmit}>
					  <div className="form-group">
					    <label for="Price">Currency:</label>
						  <label for="sel1">Select Currency</label>
						  <select class="form-control" required id="sel1" name="currency" value={inputs.currency} onChange={handleChange}>
						    <option value="">Select Currency</option>
						    {currency.map(c => <option key={c}>{c}</option>)}
						  </select>
					  </div>
					  <div className="form-group">
					    <label for="Price">Payment Price:</label>
					    <input type="hidden" className='inputAccount form-control' name='curls' value={curls} onChange={handleChange}/>
					    <input type="number" required className='inputAccount form-control' name='price' value={inputs.price} onChange={handleChange}/>
					  </div>
					  <button type="submit" className="btn btn-default">Submit</button>
					</form>
				

			</div>
		</div>
			

	</section>

	</div>
   
  )
}
export default ContactUs;
