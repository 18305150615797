import React, { Component } from 'react';
import SubModal from './SubModal';
import jQuery from 'jquery';
import { Tabs, Tab } from "react-bootstrap";
import '../data/global.js';//global.API+
const even = [0,2,4,6,8,10];
export const types =['Signal Service','Account Management'];


class Products extends Component {

constructor(props) {
    super(props);
    this.state = {
	  error: null,
	  isLoaded: false,
	  products: [],
	  products_compare:[],
	  description:'',
	  modalShow:false,
	  imodalShow:false,
	  payModalShow:false,
	  url:'',
	  paymentList:[],
	  defaultActiveKey:'',
	  type:'Signal Service',
	  error:''

    };
    // this.iframeSHow = this.iframeSHow.bind(this);
      this.handleSelect = this.handleSelect.bind(this);
    
  }

 componentDidMount() {
 	console.log(this.state.type,"PRODUCT TYPE");
 	var screenHeight = jQuery( window ).height();
	var screenWidth = jQuery( window ).width();


	if(screenWidth<767){
		var decr = 180
	}else{
		var decr = 120	
	}
	
	jQuery('.payment-buy-btn').click(function(){
		

	});

		this.handleSelect(this.state.type);
 	

      fetch(global.API+"api/v1/get_payment_method")
      .then(res => res.json())
      .then(
        (result) => {

        

	//	console.log(this.state.paymentList,'paymentList');
				 try {
		           		 this.setState({
				            isLoaded:false,
				            paymentList: result.data,
				            defaultActiveKey:result.data[0].id
				         	
			          });
		        } catch (e) {
		           // Promise.reject({body:responseBodyAsText, type:'unparsable'});
		           console.log(e,"CATCH");
		        }
        },
         (error) => {
          this.setState({
            isLoaded: true,
            error : 'error'
          });
          console.log(error,"ERRORFecth")
        }
      )


  }
	
	readMoreShow(desc){
		this.setState({
			modalShow: true,
			description:desc,
		
		});
	}

	iframeShow(iframeurl){
		this.setState({
			imodalShow: true,
			url:'https://xn--r1a.website/s/cctrade1',
		
		});
	}

	paymentModalShow(package_name){
		var screenHeight = jQuery( window ).height();
		var screenWidth = jQuery( window ).width();
		if(screenWidth<767){
			var decr = 180
		}else{
			var decr = 120	
		}
		setTimeout(function(){
			console.log(screenHeight-decr,"paymentModal Height");
				jQuery('div#faqModal .modal-body').css('max-height', screenHeight-decr);
		},500);
		this.setState({
			payModalShow: true,
			paymentList: this.state.paymentList
		});
	}
 

  	handleSelect(types){

   	this.setState({
 		isLoaded:true,
 		type:types
 	});
   console.log(types,"PRO TYPE")
   fetch(global.API+"api/v1/get_products",{method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
    		"type" : types
		})})
      .then(res => res.json())
      .then(
        (result) => {

        	//console.log(result.data,'CompareData');
         
          let tmpArray = []
            for (var i = 0; i < result.data.length; i++) {
                tmpArray.push(result.data[i].data)
            }

          this.setState({
            isLoaded:false,
            products: result.data,
         	products_compare:tmpArray
          });

		console.log(this.state.products_compare,'products_compare');

        },
         (error) => {
          this.setState({
            isLoaded: true,
            error 
          });
        }
      )
	};
	render() {
		return (
			<div  className='desktopOnly'>
				<section className="hero-section product-section ssg-page" id="Products">
					<div className="container">
						<div id="top-section" className="product-block">
							<div className="row"> 
							<div className="col-md-12 mobile-table">
								<div className="product-list-tab">
									<h2> Select Product</h2>

									<div className='products-type'>
										 <Tabs
										      id="products-type"
										      activeKey={this.state.type}
										       onSelect={(k) => this.handleSelect(k)}
										    >
										    {
										    	types.map((items,index)=>(
										    		<Tab eventKey={items} title={items}>
										        		{/*<div className=''>{items}</div>*/}
										      		</Tab>
										    	))
										    }
										      
										    
										    </Tabs>
									</div>
									<div className='table-sticky-header'>
										<table className='table products-table'>
											<thead className=''>
										  	<th className="col-2 firsColHeader">#</th>
										  	{
										  		  this.state.products.map((item,index) => (
										  		  	this.state.type==item.type?
										            <>
										            <th key={item.id} className="col-2">{item.package_name}</th>
										            <th className="custom-corner" style={{display:'none'}}>#</th>
										            </>:''

										          ))
										  	}
										  		
											
											</thead>
										</table>
									</div>
									<div className="table-responsive">
									  <table className="table table-borderless products-table" id="proTable">
									 
										<tbody className={this.state.isLoaded ? "tbody-flex":""}>
										{this.state.isLoaded ?
					      					<div class="loader"></div>:
											<tr className='products-slider'>
											{

												this.state.products_compare.map((items,i) =>(
												(i==0)?
													 <td className='leftHeadingTd'><table id="leftColumnTable" className='leftHeading' >
													 {									
													
													items.map(item =>(	
													
														<tr>
															
															<td className='compare_list'>
																{item.compare}
															</td>
														</tr>
												  
													))
												}

												</table></td> :''
												))
											}
											
												{

												this.state.products.map((items,i) =>(
													<>{console.log(items,"ITEMSDATA")}
													 <td className='rightHeadingTd'><table>
													 {									
													
													items.data.map(item =>(	

														this.state.type==items.type?
														<tr>

															{item.data!==null?

																<td>


																{(item.data.details=='Yes')?<i className="fa fa-check-square-o"></i>:
																(item.data.details=='No')?<i className="fa fa-times"></i>:item.data.details}
																</td>:
																	<td>-</td>
															}
															
														</tr>:''
												  
													))
												}

												</table></td> 
												<td className="duplicateCopare" style={{display:'none'}}>
													<table className='duplicateTable'>
														<tr>
															{

												this.state.products_compare.map((items,i) =>(
												(i==0)?
													 <td className='leftHeadingTd'><table className='leftHeading'>
													 {									
													
													items.map(item =>(	
													
														<tr>
															
															<td className='compare_list'>
																{item.compare}
															</td>
														</tr>
												  
													))
												}

												</table></td> :''
												))
											}
											
														</tr>
													</table>
												</td>

												</>
												))
											}
											  </tr>
										}
										
										</tbody>
									{/*	<tfoot className="products-footer">
										<tr>
										<th  className="col-2">#</th>
										{
									  		  this.state.products.map((item,index) => (
									          <>
									           	<th key={item.id} className="col-2">
									           	{index==0?<div className="join"><a href="javascript:void(0)" onClick={this.iframeShow.bind(this)}>{item.lable_name}</a></div>:
									           	<div className="join"><a href="javascript:void(0)" className='payment-buy-btn' onClick={this.paymentModalShow.bind(this,item.package_name)} >{item.lable_name}</a></div> }
									           	
												<div className="read-more-pro"><a href="javascript:void(0)"  onClick={this.readMoreShow.bind(this, item.description)}>Read more</a></div>
											
												</th>
												 <th className="custom-corner" style={{display:'none'}}>#</th>
									          </>
									          ))
									  	}
									  	</tr>
										</tfoot>*/}

									  </table>
								
									</div>
									<div className='table-sticky-footer'>
										<table className='table products-table'>
										<tfoot className="products-footer">
											<tr>
											<th  className="col-2">#</th>
											{
										  		  this.state.products.map((item,index) => (
										  		  		this.state.type==item.type?
										          <>
										           	<th key={item.id} className="col-2">
										           	{index==0?<div className="join"><a href="javascript:void(0)" onClick={this.iframeShow.bind(this)}>{item.lable_name}</a></div>:
										           	<div className="join"><a href="javascript:void(0)" className='payment-buy-btn' onClick={this.paymentModalShow.bind(this,item.package_name)} >{item.lable_name}</a></div> }
										           	
													<div className="read-more-pro"><a href="javascript:void(0)"  onClick={this.readMoreShow.bind(this, item.description)}>Read more</a></div>
													{/*<div className="read-more-pro"><a href={item.read_more_link} target="_blank">Read more</a> </div>*/}
													</th>
													 <th className="custom-corner" style={{display:'none'}}>#</th>
										          </>:''
										          ))
										  	}
										  	</tr>
											</tfoot>
										</table>
									</div>
									<div className='col-sm-12 bottomArrowPro' style={{display:'none'}}>
										<span className='leftScroll'><i class="fa fa-arrow-left"></i></span>
										<span className='rightScroll'><i class="fa fa-arrow-right"></i></span>
									</div>
									
								</div>

							</div>
						
						</div>
					</div>
				</div>

			<SubModal
				screen='readMore'
				// headerContent='yes'
				bodyContent={this.state.description}
				show={this.state.modalShow}
			    onHide={() => this.setState({ modalShow: false })}
			  />

			<SubModal
				screen='iframe'
				// headerContent='yes'
				bodyContent={this.state.url}
				show={this.state.imodalShow}
			    onHide={() => this.setState({ imodalShow: false })}
			  />

			<SubModal
				screen='payment'
				defaultActiveKey={this.state.defaultActiveKey}
				bodycontent={this.state.paymentList}
				show={this.state.payModalShow}
			    onHide={() => this.setState({ payModalShow: false })}
			  />


			</section>

		</div>
		);
	}
}
export default Products;