import React, { Component } from 'react';
import Homescreen from './components/Homescreen';
import LivePerfomance from './components/LivePerfomance';
import CopytradePerfomance from './components/CopytradePerfomance';
import Videos from './components/Videos';
import Products from './components/Products';
import ProductsMobile from './components/ProductsMobile';
import ContactUs from './components/ContactUs';
import ContactForm from './components/ContactForm';
import Login from './components/Login';
import BlogFAQ from './components/BlogFAQ';
import NFT from './components/NFT';
import Telegram from './components/Telegram';
import Header from './components/Header';
import Footer from './components/Footer';

class Home extends Component {
	render() {
		return (
		<>
			<Header />
			<div className="ssg-container">
				
				<Homescreen/>
				<LivePerfomance/>
				<CopytradePerfomance/>
				<Videos/>
				{console.log(window.innerWidth<768,"WINDOW_WIDTH_")}
				{

					window.innerWidth<768 ?(<ProductsMobile />):(<Products/>) 
				}
					
			
				<NFT/>
				<BlogFAQ/>
				{/*<Telegram/>*/}
				<ContactForm/>
				<ContactUs/>
				
			</div>
			<Footer/>	
		</>
		);
	}
}

export default Home;
