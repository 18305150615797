import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import { Form, Button, Accordion, Card, Header } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

function SubModal(props) {
   const [key, setKey] = useState('53');
     const [text, setText] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = (selectedText) => {
    console.log(selectedText,"SELECTED");
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    removeHTML(setText(selectedText));
  };

  function handleChange(event) {
    console.log(event.target.value,"PROPS");
    props.onChange(event.target.value);
  }

  function handleDateChange(date){
    
  }

 function copyTextToClipboard() {
    const context = this.textArea;
    context.select();
    document.execCommand("copy");
  }
  function removeHTML(str){ 
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
}
function showReadMorefun(desc){
  props.showReadMore(props.description);
}
  return (
   
     <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id={props.screen=='SignalDetail'? "SignalModal": "faqModal"}
      dialogClassName={ props.screen=='payment'? "faq-content payment-content":"faq-content"}
    >

     <Modal.Header className={ props.screen=='payment'? "paymentModalHeader":" "}>
          <h4>{props.headerContent}</h4>
           <Button variant="link" onClick={props.onHide} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="colse-done">Done</span>
          </Button>
     </Modal.Header>
    
      <Modal.Body className={ props.screen=='payment'? "submodal paymentModal": props.screen=='productsMobile'? "submodal productMobileBody":"submodal"}>
     {props.screen=='readMore' ? <div dangerouslySetInnerHTML={{__html: props.bodyContent}}>
     </div>:
     props.screen=='iframe'?<div>
      <iframe id="preview" className="teleframe" src={props.bodyContent}></iframe>
     </div>:
     props.screen=='payment'?
     <div className='payment-modal'>
    
        <Accordion defaultActiveKey={props.defaultActiveKey} className='terms_conditions'>
         {props.bodycontent.map((item, index) => (

          <Card key={item.id}>
            <Card.Header className='payment-header'>
              <Accordion.Toggle as={Button} variant="link" eventKey={item.id}>
              <span><img src={item.image} alt={item.name} className='payment-img' /></span>
              <span className='payment-img-title'>{item.name}</span>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={item.id}>
              <Card.Body className='payment-body'>
              <div className='main-card d-flex'>
                <div className='card-inner-left'>
                  <div className='payment-title'>{item.name}</div>
                  <div className='payment-description' dangerouslySetInnerHTML={{__html: item.description}}></div> 
                    {
                      item.copy_content!==""?
                    <div className='copy_content d-flex'>

                      <span className='copy_content-text' text={item.copy_content.replace(/<[^>]+>/g, '')}  dangerouslySetInnerHTML={{__html: item.copy_content}}></span>
                      {item.copy_allowed==1?
                      <CopyToClipboard text={item.copy_content.replace(/<[^>]+>/g, '')} onCopy={onCopyText}>
                        <span className='copy-button' >{isCopied ? "Copied!" :  <i class="fa fa-copy"></i>}</span>
                        </CopyToClipboard>:''}

                    </div>:""
                  }
                </div>
              <div className='card-inner-right ml-auto'>
                {
                  item.barcode_image !== null ?
                  <img src={item.barcode_image} alt="SCANNER" class="payment-img" />
                  : ""
                }
              </div>
              </div>
               
       
               </Card.Body>
            </Accordion.Collapse>
          </Card>
            ))
           }
           </Accordion>
    

     </div>:
      props.screen=='productsMobile'?
      <div className='productsMobile-column'>
         <div className='productsMobile-header'></div>
            <div className='compare-list-show'>
              {props.bodycontent.map((item,index)=>(
                <>
                  <div className={index==0?'compare-left colleft first-row':'compare-left colleft'}>
                    {item.compare}
                  </div>
                    {item.data!==null?
                  <div className={index==0?'details-right colleft first-row':'details-right colleft'}>
                   {(item.data.details=='Yes')?<i className="fa fa-check-square-o"></i>:
                     (item.data.details=='No')?<i className="fa fa-times"></i>:item.data.details}
                  </div>:<div  className={index==0?'details-right colleft first-row':'details-right colleft'}>-</div>}
                  </>
                ))
            }
           </div>
       <div className='productsMobile-footer'>
         <div className='compare-left colleft product-btn'>
         {props.dataLabel=='CLICK TO JOIN'?
           <div className="joinclick"><a href="javascript:void(0)" onClick={props.showIframe} className='payment-buy-btn'>{props.dataLabel}</a></div>:
           <div className="joinclick"><a href="javascript:void(0)" onClick={props.showPayment} className='payment-buy-btn'>{props.dataLabel}</a></div>
         }
          </div>
          <div className='details-right colleft  product-btn'>
             <div className="joinclick"><a href="javascript:void(0)" onClick={showReadMorefun} className='payment-buy-btn'>Read more</a></div>
          </div>
       </div>
     </div>:
     props.screen=='singleChartImage'?
     <div className='singleChartImage'>
      <img src={props.bodyContent} alt={props.bodyContent} className='chartSingleImage' />
     </div>:
     <div dangerouslySetInnerHTML={{__html: props.bodyContent}}></div>}

  
      </Modal.Body>

    
    </Modal>
   
  )
}

export default SubModal;