import React, { Component } from 'react';
import { Form, Button } from "react-bootstrap";

function SupportHome(props) {
  return (
      
     <div className="ssg-child-right">
		  <div id="top-section" className="support-home">
				<div className="">
					<div className='col-md-8 col-md-offset-2'>
					<div className='row support'>
						<div className='col-md-7 p-0'>
								<div className="login-box">
									<h2>Submit a ticket</h2>
										<Form className='login-Form'>
										  <Form.Group controlId="formBasicEmail">
										  
										    <Form.Control type="text" className='inputTitle ticketInput' placeholder="Title" />
										     <Form.Control type="text" className='inputLocation ticketInput' placeholder="Location" />
										      <Form.Control type="text" className='inputDesc ticketInput' placeholder="Description" />
										   
										  </Form.Group>
										  <Button className='submitButton' variant="primary" type="submit">
										    Submit
										  </Button>
										</Form>
									 
								</div>
						</div>
						<div className='col-md-5 p-0'>
							<div className='ticket-box'>
								<div className='title-ticket'>Your Ticket</div>
									<div className='list-ticket'>
										<ol className='list-of-ticket'>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>
											<li><a href="#" >Dev Test</a></li>

											

										</ol>
									</div>
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
		</div>
	<button className="blog-btn back-btn">Back</button>
		
    </div>
  );
}


export default SupportHome;