export const expertVideoSettings = {
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoWidth: true,
      responsive: [
        {
            breakpoint: 768,
            settings: {
              
                slidesToShow: 3,
                slidesToScroll: 1,

                
            },
             breakpoint: 560,
             settings: {
              
                slidesToShow: 1,
                slidesToScroll: 1,
                
            }
        }
    ]
};

export const perfomanceSliderSettings = {
        
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        responsive: [
        {
                breakpoint: 960,
                settings: {
                    infinite: false,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,

                },
                breakpoint: 768,
                settings: {
                    infinite: true,
                    centerMode: true,
                    slidesToShow:3,
                    slidesToScroll: 1,

                },
                 breakpoint: 767,
                settings: {
                  centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            }
        ]
 
};

export const copytradeSliderSettings = {
        
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        responsive: [
        {
                breakpoint: 960,
                settings: {
                    infinite: false,
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,

                },
                breakpoint: 768,
                settings: {
                    infinite: true,
                    centerMode: true,
                    slidesToShow:2,
                    slidesToScroll: 1,

                },
                 breakpoint: 767,
                settings: {
                  centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            }
        ]
 
};

export const perfomance_slider =[
  {
    id:1,
    imgUrl:'assets/images/imgSlider1.png',
    title:'TRADING SIGNALS',
    dataTarget:'#trading-signals',
    dataTargetID:'trading-signals'
  },
  {
    id:2,
    imgUrl:'assets/images/imgSlider2.png',
    title:'INVESTMENT SIGNALS',
    dataTarget:'#investment-signals',
    dataTargetID:'investment-signals'
  },
  {
    id:3,
    imgUrl:'assets/images/imgSlider3.png',
    title:'LIVE DEMAND CHART',
    dataTarget:'#live-demand-chart',
    dataTargetID:'live-demand-chart'
  },
  {
    id:4,
    imgUrl:'assets/images/imgSlider4.png',
    title:'LIVE TRADE CHART',
    dataTarget:'#live-trade-chart',
    dataTargetID:'dynamicPopupTradeChart'
  },
];

export const copytrade_slider =[
  {
    id:1,
    imgUrl:'assets/images/analytics.png',
    title:'Copytrade Chart',
    dataTarget:'#copytrade-chart',
    dataTargetID:'copytrade-chart'
  },
  {
    id:2,
    imgUrl:'assets/images/multimedia.png',
    title:'Performance Video',
    dataTarget:'#performance-video',
    dataTargetID:'performance-video'
  },
  {
    id:3,
    imgUrl:'assets/images/excel.png',
    title:'Performance Excel',
    dataTarget:'#performance-excel',
    dataTargetID:'performance-excel'
  },
];

export const youtube_links =[
  {
    id:1,
    title:'EXCHANGE B1',
    url:'https://www.youtube.com/embed/vYxRgXi5aQI'
  },
   {
    id:2,
    title:'EXCHANGE B1',
    url:'https://www.youtube.com/embed/iDMdwyIKja4'
  },
  {
    id:3,
    title:'EXCHANGE B1',
    url:'https://www.youtube.com/embed/cDDTplUY4Qk'
  },
  {
    id:4,
    title:'EXCHANGE B1',
    url:'https://www.youtube.com/embed/jtoWZQ6fGwY'
  },
  {
    id:5,
    title:'EXCHANGE B1',
    url:'https://www.youtube.com/embed/Wz8zpMSBshM'
  },
  {
    id:6,
    title:'EXCHANGE B1',
    url:'https://www.youtube.com/embed/vYxRgXi5aQI'
  },
   {
    id:7,
    title:'EXCHANGE B1',
    url:'https://www.youtube.com/embed/iDMdwyIKja4'
  }

];

export const social_media =[
      {
        id:1,
        name:'Facebook',
        url:'https://www.facebook.com/CryptoTrade1-156886548258635/',
        image:'assets/images/facebook.png'
      },
      {
        id:2,
        name:'Youtube',
        url:'https://youtube.com/cryptotrade1',
        image:'assets/images/youtube.png'
      },
      {
        id:3,
        name:'Telegram',
        url:'https://t.me/cctrade1',
        image:'assets/images/telegram.png'
      },
      {
        id:4,
        name:'Twitter',
        url:'https://twitter.com/cctrade11',
        image:'assets/images/twitter.png'
      },
      //   {
      //   id:5,
      //   name:'Skype',
      //   url:'',
      //   image:'assets/images/skype.png'
      // },
      {
        id:5,
        name:'WhatsApp',
        url:'https://wa.me/971529920903',
        image:'assets/images/whatsapp.png'
      },
     

    ];



export const script_name_arr =[
      {
        id:1,
        script_name:'ADXBTC'  
      },
      {
        id:2,
        script_name:'AIONBTC'  
      },
      {
        id:3,
        script_name:'BATBTC'  
      },
      {
        id:4,
        script_name:'BCHUSD'  
      },
      {
        id:5,
        script_name:'BQXBTC'  
      },
       {
        id:6,
        script_name:'BTTBTC'  
      },
       {
        id:7,
        script_name:'DLTBTC'  
      },
      {
        id:8,
        script_name:'ENJBTC'  
      },
      {
        id:9,
        script_name:'ETHUSD'  
      },
      {
        id:10,
        script_name:'GASBTC'  
      },
      {
        id:11,
        script_name:'GVTBTC'  
      },
      {
        id:12,
        script_name:'LTCUSD'  
      },
       {
        id:13,
        script_name:'OSTBTC'  
      },
      {
        id:14,
        script_name:'QSPBTC'  
      },
      {
        id:15,
        script_name:'TRIGBTC'  
      },
       {
        id:16,
        script_name:'WAVESBTC'  
      },
      {
        id:17,
        script_name:'WTCBTC'  
      },
      {
        id:18,
        script_name:'LTCUSD'  
      },
       {
        id:19,
        script_name:'XVGBTC'  
      },
      {
        id:20,
        script_name:'ZRXBTC'  
      },

    ];

export  const buy_sell_arr =[
      {
        id:1,
        buy_sell:'BUY'
      },
       {
        id:2,
        buy_sell:'SELL'
      },
       {
        id:3,
        buy_sell:'Rollover'
      }
];

export const settings = {
   
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
             breakpoint: 768,
        settings: {
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,

                },
        }
     ]
  };



export const settingsChart = {
  
     
    speed: 500,
    slidesToShow: 4,
    slidesToScroll:1,
     infinite: false,
     responsive: [
        {
        breakpoint: 480, // mobile breakpoint
        settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        variableWidth:true
        
            }
        }
     ]
};

export const requestOptions = {
        method: 'POST',
        headers: { 
         
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'cat_id': 2})
 };
 
 export const requestOptionsDemand = {
        method: 'POST',
        headers: { 
         
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'cat_id': 1})
};   

