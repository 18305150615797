import React, { Component } from 'react';
import SubModal from './SubModal';
import { Tabs, Tab } from "react-bootstrap";
import jQuery from 'jquery';
import '../data/global.js';//global.API+

const even = [0,2,4,6,8,10];
export const types =['Signal Service','Account Management'];
class ProductsMobile extends Component {

constructor(props) {
    super(props);
    this.state = {
	  error: null,
	  isLoaded: false,
	  products: [],
	  products_compare:[],
	  description:'',
	  modalShow:false,
	  imodalShow:false,
	  payModalShow:false,
	  url:'',
	  paymentList:[],
	  defaultActiveKey:'',
	  productMobileShow:false,
	  compareList:[],
	  dataUrl:'',
	  dataLabel:'',
	  type:'Signal Service'

    };
    // this.iframeSHow = this.iframeSHow.bind(this);
      this.handleSelect = this.handleSelect.bind(this);
  }

 componentDidMount() {
 	
 	var screenHeight = jQuery( window ).height();
	var screenWidth = jQuery( window ).width();
	if(screenWidth<767){
		var decr = 180
	}else{
		var decr = 120	
	}
	
	jQuery('.payment-buy-btn').click(function(){
		

	});

 		this.handleSelect(this.state.type);
 	

      fetch(global.API+"api/v1/get_payment_method")
      .then(res => res.json())
      .then(
        (result) => {

          this.setState({
            isLoaded:false,
            paymentList: result.data,
            defaultActiveKey:result.data[0].id
         	
          });

		console.log(this.state.paymentList,'paymentList');

        },
         (error) => {
          this.setState({
            isLoaded: true,
            error 
          });
        }
      )


  }
	
	readMoreShow(desc){
		this.setState({
			modalShow: true,
			description:desc,
		
		});
	}

	iframeShow(iframeurl){
		this.setState({
			imodalShow: true,
			url:'https://xn--r1a.website/s/cctrade1',
		
		});
	}

	paymentModalShow(package_name){
		var screenHeight = jQuery( window ).height();
		var screenWidth = jQuery( window ).width();
		if(screenWidth<767){
			var decr = 180
		}else{
			var decr = 120	
		}
		setTimeout(function(){
			console.log(screenHeight-decr,"paymentModal Height");
				jQuery('div#faqModal .modal-body').css('max-height', screenHeight-decr);
		},500);
		this.setState({
			payModalShow: true,
			paymentList: this.state.paymentList
		});
	}

	openProductMobileModal(items,url,label,desc){
		console.log(url,"URL");
		console.log(label,"label");
		this.setState({
			productMobileShow: true,
			compareList:items,
			dataUrl:url,
			dataLabel:label,
			description:desc
		
		});
	}

	handleSelect(types){

   	this.setState({
 		isLoaded:true,
 		type:types
 	});
   
   fetch(global.API+"api/v1/get_products",{method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
    		"type" : types
		})})
      .then(res => res.json())
      .then(
        (result) => {

        	//console.log(result.data,'CompareData');
         
          let tmpArray = []
            for (var i = 0; i < result.data.length; i++) {
                tmpArray.push(result.data[i].data)
            }

          this.setState({
            isLoaded:false,
            products: result.data,
         	products_compare:tmpArray
          });

	//	console.log(this.state.products,'products_compare');

        },
         (error) => {
          this.setState({
            isLoaded: true,
            error 
          });
        }
      )
	}
	render() {
		return (
			<div style={{display:'none'}} className='mobileOnly'>
				<section className="hero-section product-section ssg-page" id="Products">
					<div className="container">
						<div id="top-section" className="product-block">
							<div className="row"> 
							<div className="col-md-12 mobile-table">
								<div className="product-list-tab">
									<h2> Select Product</h2>
								<div className='products-type mobile-version'>
									 <Tabs
								      id="products-type"
								      activeKey={this.state.type}
								      onSelect={(k) => this.handleSelect(k)}
									  >
										    {
										    	types.map((items,index)=>(
										    		<Tab eventKey={items} title={items} className={index==0?'active':''}>
										        		{/*<div className=''>{items}</div>*/}
										      	</Tab>
										    	))
										    }
										      
										    
										    </Tabs>
									</div>

									<div className='row products-mobile-only'>
										{
									  		  this.state.products.map((item,index) => (
									  		  	this.state.type==item.type?
									  		 	<div key={item.id}  className='col'>
													<div className="light-count" onClick={this.openProductMobileModal.bind(this,item.data,item.url,item.lable_name,item.description)}>
															<div className='title-package'>{item.package_name}</div>
														
													</div>
												</div>:''
									          
									          ))
									  	}
										
										
									

									</div>
							
								</div>

							</div>
						
						</div>
					</div>
				</div>

			<SubModal
				screen='readMore'
				// headerContent='yes'
				bodyContent={this.state.description}
				show={this.state.modalShow}
			    onHide={() => this.setState({ modalShow: false })}
			  />

			<SubModal
				screen='iframe'
				// headerContent='yes'
				bodyContent={this.state.url}
				show={this.state.imodalShow}
			    onHide={() => this.setState({ imodalShow: false })}
			  />

			<SubModal
				screen='payment'
				defaultActiveKey={this.state.defaultActiveKey}
				bodycontent={this.state.paymentList}
				show={this.state.payModalShow}
			    onHide={() => this.setState({ payModalShow: false })}
			  />

			 <SubModal
				screen='productsMobile'
				dataUrl={this.state.dataUrl}
				dataLabel={this.state.dataLabel}
				description={this.state.description}
				bodycontent={this.state.compareList}
				hidePayment={() => this.setState({ payModalShow: false })}
				showPayment={() => this.setState({ payModalShow: true })}
				showIframe={() => this.setState({ imodalShow: true })}
				showReadMore={this.readMoreShow.bind(this) }
				show={this.state.productMobileShow}
			    onHide={() => this.setState({ productMobileShow: false })}
			  />


			</section>

		</div>
		);
	}
}
export default ProductsMobile;