import React, { Component } from 'react';
import ModalHeader from './ModalHeader';
import CopytradeModalHeader from './CopytradeModalHeader';
import Slider from 'react-slick';
import SubModal from './SubModal';
import {settings, settingsChart, requestOptions, requestOptionsDemand, script_name_arr, buy_sell_arr} from '../data/localData';
import '../data/global.js';//global.API+
import { Modal,Form, Button  } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Moment from 'moment';


class Footer extends Component {

	constructor(props){
		super(props);
		this.state = {
		  error: null,
		  showbutton:true,
	      isLoaded: false,
	      signalInvest: [],
	      signalTrade: [],
	      chartData:[],
	      chartDataDemand:[],
	      tradeyear: '',
	      demandyear: '',
	      trademonth: '',
	      demandmonth:'',
	      webData:'',
	      chartImages:[],
	      copychartImages:[],
	      copychartVideos:[],
	      chartImagesDemand:[],
	      active:[],
	      activeYear:false,
	      newTradeIndex:0,
	      newDemandIndex:0,
	      cat_id:2,
	      modalShow: false,
	      singleImageChart:null,
	      dayTradeScript:[],
	      dayTrade:[],
	      dayTradeMore:[],
	      showMoreModal:false,
	      script_name:null,
	      buy_sell:null,
	      from:null,
	      to:null,
	      id:'',
	      last_id:'',
	      selected:''
	      
		};
		this.openImages = this.openImages.bind(this);
		this.openCopyImages = this.openCopyImages.bind(this);
		this.openCopyVideo = this.openCopyVideo.bind(this);
		this.showDetailSignal = this.showDetailSignal.bind(this);
		this.closeDetailSignal = this.closeDetailSignal.bind(this);
		this.submitSearch = this.submitSearch.bind(this);
		this.showDetailMore = this.showDetailMore.bind(this);
		this.showDetailMoreVideo = this.showDetailMoreVideo.bind(this);
	}

	 componentDidMount(){
	// console.log(global.API,"COMMAN_API");
		this.handleDefaultTrade();
		this.openCopyImages();
		this.openCopyVideo();

		fetch(global.API+'api/v1/get_investmentsignals')
		.then(res=> res.json())
		.then(
			(result)=>{
			// console.log(result.data.trade,"TRADE");
			
				this.setState({
					signalInvest:result.data.trade
				});
			//console.log(tmpSigal,'tmpSigal');
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)

		fetch(global.API+'api/v1/get_web_data')
		.then(res=> res.json())
		.then(
			(result)=>{
			// console.log(result.data.trade,"TRADE");
			
				this.setState({
					webData:result.data
				});
			//console.log(tmpSigal,'tmpSigal');
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)


		// Trade API
		fetch(global.API+'api/v1/get_live_trade_chart',requestOptions)
		.then(res=> res.json())
		.then(
			(result)=>{
			// console.log(result.data,"Chart");
			
				this.setState({
					chartData:result.data,
					trademonth:result.data[0].folder[0].month,
					tradeyear:result.data[0].folder[0].year
				});
				this.openImages(result.data[0].folder[0].month,result.data[0].folder[0].year);
			
			 
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)

		//Demand API
		fetch(global.API+'api/v1/get_live_trade_chart',requestOptionsDemand)
		.then(res=> res.json())
		.then(
			(result)=>{
			// console.log(result.data,"ChartDemand");
			
				this.setState({
					chartDataDemand:result.data,
					demandmonth:result.data[0].folder[0].month,
					demandyear: result.data[0].folder[0].year
				});
				
				this.openImagesDemand(result.data[0].folder[0].month,result.data[0].folder[0].year);
			 
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)

	}

	handleDefaultTrade(){
		this.setState({
			isLoaded:true
		});
		fetch(global.API+'api/v1/get_daytrade')
		.then(res=> res.json())
		.then(
			(result)=>{
			 console.log(result.data.trade,"TRADE");
		 console.log(result.data.script,"SCRIPT");
				let arr_length = result.data.trade.length;
				this.setState({
					signalTrade:result.data.trade,
					dayTrade:result.data.trade,
					dayTradeScript:result.data.script,
					id: result.data.trade[arr_length-1].id,
					isLoaded:false
				});
			console.log(this.state.id,'LAST ID');
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
	}
	 handleFromDateChange = setdate => {
    //    console.log('date: ', date)
  
		this.setState({
			from: setdate,
		});
      
    }
   handleFromToChange = date => {
      //  console.log('date: ', date)
        this.setState({
            to: date
        });
    }
	showDetailSignal(){
		this.setState({
			showMoreModal:true,
			
		});
		this.handleDefaultTrade();

	}
	closeDetailSignal(){
		this.setState({
			showMoreModal:false,
			showbutton:true,
			dayTrade:[],
			from:'',
			to:'',
			script_name:'',
			buy_sell:''
		});

	}
	openDemandMonth(index){
		
		 // const newCommentReplyIds = [...this.state.active, id];
		  this.setState({
		  	activeYear: !this.state.activeYear,
		    newDemandIndex:index
		});
	}

	openTradeMonth(index){
		
		 // const newCommentReplyIds = [...this.state.active, id];
		  this.setState({
		  	activeYear: !this.state.activeYear,
		    newTradeIndex:index
		});
	}
	selectScript(event){
		this.setState({
			script_name: event.target.value
		});
	}
	selectBuysell(event){
		this.setState({buy_sell: event.target.value});
	}
	submitSearch(){
		this.setState({
			isLoaded:true,
			showbutton:false
		});
			let fromTo;
			let fromDate;
		
		if(this.state.from===null || this.state.to===null){
			 fromTo = "";
			 fromDate = "";
		}else{
			let fdate = new Date(this.state.from);
			let todate = new Date(this.state.to);
			let fdd = fdate.getDate();
			let fmm = fdate.getMonth() + 1;
			let fyyyy = fdate.getFullYear();

			let dd = todate.getDate();
			let mm = todate.getMonth() + 1;
			let yyyy = todate.getFullYear();

			if(fmm < 10) {    fmm = '0' + fmm;	} else {   fmm = '' + fmm;}

			if(fdd < 10) {   fdd = '0' + fdd;	} else {   fdd = '' + fdd;}

			if(mm < 10) {    mm = '0' + mm;	} else {   mm = '' + mm;}

			if(dd < 10) {   dd = '0' + dd;	} else {   dd = '' + dd;}

			 fromTo = yyyy + "-" + mm + "-" + dd;
			 fromDate = fyyyy + "-" + fmm + "-" + fdd;
		}
				
		
		
		// console.log(fromDate,"FROM DATE");
		// console.log(fromTo,"FROM TO");
		fetch(global.API+'api/v1/get_daytrade',{method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        	"script_name" : this.state.script_name,
		    "buy_sell" : this.state.buy_sell,
		    "from" : fromDate,
		    "to" : fromTo,   
        })})
		.then(res=> res.json())
		.then(
			(result)=>{
			// console.log(result.data.trade,"TRADE");
			 
				let arr_length = result.data.trade.length;
				this.setState({
					dayTrade:result.data.trade,
					isLoaded:false
					//id: result.data.trade[arr_length-1].id
				
				});
			console.log(this.state.dayTrade,'SEARCH DATA');
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)


		//console.log(this.state.from+this.state.to+this.state.script_name+this.state.buy_sell)
	}

	handleChangeSignal(script,type,datefrom,todate,id){
		this.setState({
			script_name:script,
			buy_sell:type,
			from:datefrom,
			to: todate,
			id:id
		});
	}
	showDetailMore(){

		fetch(global.API+'api/v1/get_daytrade',{method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'id': this.state.id})})
		.then(res=> res.json())
		.then(
			(result)=>{
				let arr_length = result.data.trade.length;
			   this.setState({
					dayTrade: [...this.state.dayTrade, ...result.data.trade],
					id: result.data.trade[arr_length-1].id,
					//dayTradeScript:result.data.script
				});
			console.log(this.state.dayTrade,'After Merge');
					console.log(this.state.id,'LAST ID');
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)


	}

	showDetailMoreVideo(){
		console.log(this.state.last_id);
		fetch(global.API+'api/v1/get_copytrade_video',{method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'id': this.state.last_id})})
		.then(res=> res.json())
		.then(
			(result)=>{
				let arr_length = result.data.length;
			   this.setState({
					copychartVideos: [...this.state.copychartVideos, ...result.data],
					last_id:result.data[arr_length-1].id,
					isLoaded:false,
				});
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)


	}
	openImages(chartMonth,chartYear){
		this.setState({
			isLoaded:true
		});
	fetch(global.API+'api/v1/get_chart',{method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "cat_id" : 2,
							    "year" : chartYear,
							    "month" : chartMonth})})
		.then(res=> res.json())
		.then(
			(imageData)=>{
			// console.log(imageData.data,"imageData");
			
				this.setState({
					activeYear: false,
					chartImages :imageData.data,
					trademonth:chartMonth,
					tradeyear:chartYear,
					isLoaded:false
				});
				
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
	
	}
	openCopyImages(){
		this.setState({
			isLoaded:true
		});
		fetch(global.API+'api/v1/get_copytrade_chart')
		.then(res=> res.json())
		.then(
			(imageData)=>{
				console.log(imageData)
				this.setState({
					copychartImages:imageData.data,
					isLoaded:false
				});
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
	
	}
	openCopyVideo(){
		this.setState({
			isLoaded:true
		});
		fetch(global.API+'api/v1/get_copytrade_video')
		.then(res=> res.json())
		.then(
			(imageData)=>{
				console.log(imageData)
				let arr_length = imageData.data.length;
				this.setState({
					copychartVideos:imageData.data,
					last_id:imageData.data[arr_length-1].id,
					isLoaded:false,
				});
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
	
	}

	openImagesDemand(chartMonth,chartYear){
		this.setState({
			isLoaded:true
		});

	fetch(global.API+'api/v1/get_chart',{method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "cat_id" : 1,
							    "year" : chartYear,
							    "month" : chartMonth})})
		.then(res=> res.json())
		.then(
			(imageData)=>{
			// console.log(imageData.data,"imageData");
			
				this.setState({
					activeYear: false,
					chartImagesDemand :imageData.data,
					demandmonth:chartMonth,
					demandyear: chartYear,
					isLoaded:false
				});
				
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
	
	}

	downloadimdf(image,name){

		//console.log(image);
		/*const link = document.createElement("a");
				link.href = image;
				link.setAttribute("download", "image.png"); //or any other extension
				document.body.appendChild(link);
				link.click();*/
		fetch(image, {
			method: "GET",
			headers: {}
		  })
			.then(response => {
			  response.arrayBuffer().then(function(buffer) {
				const url = window.URL.createObjectURL(new Blob([buffer]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", name); //or any other extension
				document.body.appendChild(link);
				link.click();
			  });
			})
			.catch(err => {
			  console.log(err);
			});
	}

	imageChartShow(image){

		this.setState({
			modalShow: true,
			singleImageChart: image,
		
		});
		console.log(this.state.singleImageChart ,"CHARTIMAGES");
	}


	
	render() {
		
		return (
			<div>


			{/* Trade copytrade chart */}
					<div className="modal fade dynamicPopup" id="copytrade-chart" role="dialog" aria-labelledby="dynamicPopupInvestment" aria-hidden="true">
					 	<div className="modal-dialog  report-popup" role="document">
					    	<div className="modal-content report-popup-content">
					       
					    		<CopytradeModalHeader headerImg="assets/images/analytics.png" />
					     		 <div className="modal-body report-popup-body TradeDemandChart">
					      			<div className='chartIframe'></div>
					      			<div className="row liveTradeData">
					      			
					      			<div className="col-md-8">
					      			
									
											<div className={this.state.isLoaded?"chartImages-block loaded-flex":"chartImages-block"}>
											 {this.state.isLoaded ?
					      						<div className="loader"></div>:
												<div className="row">
												{this.state.copychartImages.map((items,i)=>(

													<div key={items.id} className="col-md-3">
														<div className="chartImagesBox">
															<img onClick={this.imageChartShow.bind(this, items.chart_image)}  src={items.chart_image} className='chartImage' />
															<div className="bottom-chart">
															<span className="chart-title">{items.title}</span><span>
															<a href={items.base_image} onClick={() => this.downloadimdf(items.base_image,items.name)}>
																<img className='folder-img' src="assets/images/folder.png" />
															</a>
															

															</span>
															</div>
														</div>
													</div>

												))}
													
													

												 </div>
												}
											</div>
									
							
										</div>
									
								</div>
					     

					     	 </div>
					     	
							</div>
						</div>

					</div>

			{/* performance  video */}
				<div className="modal fade dynamicPopup" id="performance-video" role="dialog" aria-labelledby="dynamicPopupInvestment" aria-hidden="true">
					  <div className="modal-dialog  report-popup" role="document">
					    <div className="modal-content report-popup-content">
					       
					    <CopytradeModalHeader headerImg="assets/images/multimedia.png" />
					      <div className="modal-body report-popup-body">
					      	<div className='chartIframe'>
					      		
					      	</div>
					      	<div className="row liveTradeData">
					      			
					      			<div className="col-md-8">
					      			
									
											<div className={this.state.isLoaded?"chartImages-block loaded-flex":"chartImages-block"}>
											 {this.state.isLoaded ?
					      						<div className="loader"></div>:
												<div className="row">
												{this.state.copychartVideos.map((items,i)=>(

													<div key={items.id} className="col-md-4">
														<div className="chartImagesBox">
														<iframe width="250" height="auto" src={items.link} className='chartImage' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
															<div className='currentMonth'><h6>{items.title}</h6></div>
														</div>
													</div>

												))}
													
													

												 </div>
												}
											</div>
									
										    <div className="view-more col-md-12">
													<button className="view-more-btn view-more-signal" onClick={this.showDetailMoreVideo}>
														Show More <i class="fa fa-angle-down" aria-hidden="true"></i>
													</button>
												</div>
										</div>
									
								</div>
					      </div>
						</div>
					 </div>
				</div>

			{/* performance excel */}
				<div className="modal fade dynamicPopup" id="performance-excel" role="dialog" aria-labelledby="dynamicPopupInvestment" aria-hidden="true">
					  <div className="modal-dialog  report-popup" role="document">
					    <div className="modal-content report-popup-content">
					       
					    <CopytradeModalHeader headerImg="assets/images/excel.png" />
					      <div className="modal-body report-popup-body">
					      	<div className='chartIframe'>
					      		<iframe id="excelFrame" width="100%" height="600px" frameborder="0" scrolling="no" src={this.state.webData.copytrade_excel}></iframe>
					      	</div>
					      	
					      </div>
						</div>
					 </div>
				</div>
				
		<div className="modal fade dynamicPopup" id="investment-signals" role="dialog" aria-labelledby="dynamicPopupInvestment" aria-hidden="true">
			<div className="modal-dialog  report-popup" role="document">
			    <div className="modal-content report-popup-content">
			       <ModalHeader headerImg="assets/images/imgSlider2.png" />
					      
					      <div className="modal-body report-popup-body">
					      	<div className="chartIframe"></div>
					      	<Slider {...settings} className='liveTradeData'>
					      	{this.state.signalInvest.map((items,i) =>(
							

					    		<div key={items.id} className="box-gv">
					      			<div className="gvbtbctc d-flex justify-content-between text-white border border-light">
					      				<div className="date">{items.date}</div>
					      				{
					      						items.live_call_post.map(item => (
					      							<div key={item.id} className="report-val">{item.buy_sell} {item.currency}</div>
					      					))
					      				
					      				}
					      				
					      			</div>
					      			<div className="gvbtbctc d-flex  justify-content-between text-white border border-light">
					      				<div className="report-title">CAPITAL INVESTMENT : {items.capital_invested}</div>
					      				<div className="report-val">P/L : {items.profit_loss} BTC</div>
					      			</div>

					      			  <div className="gvbtbctc calc-box d-flex justify-content-between text-white  border border-light">
					      				
					      				<div className="col-md-12 calc">
					      					<div><span>FINAL CALCULATION</span><span className="report-val">{items.trading_days} DAYS</span></div>
					      					<p style={{height:'26px'}}>{items.calculation}</p>
					      				</div>
					      				
					      			</div>
					      			{
					      			items.live_call_post.map(item => (
					      		 	<div key={item.id} className="gvbtbctc d-flex justify-content-between text-white  border border-light">
				      				
					      						
					      								<div className="report-title">LIVE CALL POST</div>      					      				
					      					      			
									      				<div className="small-date">{item.live_call_date}</div>
									      				<div className="social-icons">
									      					{
									      						(item.twitter_link!=='')?
									      						<a href={item.twitter_link} target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a>
									      						:''
									      					} 
									      					{
									      						(item.telegram_link!=='')?
									      						<a href={item.telegram_link} target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a>
									      						:''
									      					} 
									      					
									      				</div>
					      							
					      		      				
					      				</div>
					      				))
							      			}

						      				{
						      					items.live_call_post.map(item => (
						      							<div key={item.id} className="text-center text-white gvtbtc-title">{item.buy_sell} {item.currency}</div>
						      					))
						      				
						      				}
							      		
						      				{items.results_update.map(item => (

											<div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
												<div className="report-title">LIVE CALL POST
													<div>
													<span className="range">{item.movement}</span><span className="tp"> Next TP - {item.next_tp}</span>
													</div>
												</div>      					      				
													      			
													<div className="small-date">{item.update_call_date}</div>
													<div className="social-icons">
													{
														(item.twitter_link!=='')?
														<span>
														<a href={item.twitter_link} target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a>
														</span>:''
													}
													{
														(item.chart_image!=='')?
														<span><a href="#" onClick={this.imageChartShow.bind(this, item.chart_image)} ><i className="fa fa-bar-chart" aria-hidden="true"></i></a></span>:''
														
													}
														
														
															</div>
													</div>	))}
												</div>
											
												))
										}  	
					      		
					      	</Slider>
					     

					    </div>
					     	
				    </div>
				  </div>
				</div>

			{/* Trading Signal */}
				<div className="modal fade dynamicPopup" id="trading-signals" role="dialog" aria-labelledby="dynamicPopupInvestment" aria-hidden="true">
					  <div className="modal-dialog  report-popup" role="document">
					    <div className="modal-content report-popup-content">
					       
					    <ModalHeader headerImg="assets/images/imgSlider1.png" />
					      <div className="modal-body report-popup-body">
					      	<div className='chartIframe'></div>
					      	<Slider {...settings} className='liveTradeData'>
					      	{
					      	 this.state.signalTrade.map((items,i) =>(
								<div className="box-gv">
									<div>
									   <div className="gvbtbctc d-flex justify-content-between text-white border border-light">
									      <div className="date">{items.buy_sell} {items.script_name}</div>
									      <div className="report-val">TRADING QTY : {items.trading_qty} </div>
									   </div>
									   <div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
									      <div className="col-md-12 calc">
									         <div><span>Entry :{items.t_entry}</span><span className="report-val"> Entry Date : {items.sheet_date}</span></div>
									         <div><span>Exit :{items.t_exit}</span><span className="report-val"> Exit Date : {items.exit_date}</span></div>
									       
									      </div>
									   </div>
									  <div className="gvbtbctc calc-box d-flex justify-content-between text-white  border border-light">
					      				
					      				<div className="col-md-12 calc">
					      					<div><span>FINAL CALCULATION</span></div>
					      					<p style={{height:'26px'}}>{items.calculation}</p>
					      				</div>
					      				
					      			</div>

					      			 <div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
									      <div className="col-md-12 calc">
									         <div><span>Profit :{items.profit_loss}</span><span className="report-val"> Days : {items.days}</span></div>
									         <div><span>ROI :{items.roi}</span><span className="report-val"> SL : {items.sl}</span></div>
									       
									      </div>
									   </div>
									  
									 
									   <div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
									     
									      
									      <div className="social-icons">
									      {(items.telegram_entry!=='')? <span>
									         <a href={items.telegram_entry} target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a>
									         </span>:''

									      }
									      {(items.telegram_exit!=='')?
									      	<span><a href={items.telegram_exit} target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a></span>:''
									      }
									        
									         
									      </div>

									       <div className="social-icons">
									      {(items.twitter_one!=='')? <span>
									         <a href={items.twitter_one} target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a>
									         </span>:''

									      }
									      {(items.twitter_two!=='')?
									      	<span><a href={items.twitter_two} target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></span>:''
									      }
									        
									         
									      </div>
									   </div>
									   </div>
									</div>

								))
							}  	

						</Slider>

					      <div className="view-more col pt-2">
								<button className="view-more-btn view-more-signal" onClick={this.showDetailSignal}>
									View More <img src="assets/images/arrow.png" className="arrow-see-more" /></button>
							</div>
					     	

					      </div>
					     
					     
					     	
						</div>
					 </div>
				</div>

			{/* Trade Chart */}
					<div className="modal fade dynamicPopup" id="live-trade-chart" role="dialog" aria-labelledby="dynamicPopupInvestment" aria-hidden="true">
					 	<div className="modal-dialog  report-popup" role="document">
					    	<div className="modal-content report-popup-content">
					       
					    		<ModalHeader headerImg="assets/images/imgSlider4.png" month={this.state.trademonth} />
					     		 <div className="modal-body report-popup-body TradeDemandChart">
					      			<div className='chartIframe'></div>
					      			<div className="row liveTradeData">
					      			
					      			<div className="col-md-8">
					      			
						      			<Slider {...settingsChart} className='liveTradeData'>
										      	{ this.state.chartData.map((items,index) => (

										      	
										      			<div key={items.id} style={{width:'121px'}}>
															<div className="box-year">
																<div className={ this.state.newTradeIndex==index ? 'year active':'year' } onClick={this.openTradeMonth.bind(this,index)}>{items.year}</div>
																{this.state.activeYear==true ?
														           
																	<div className={ this.state.newTradeIndex==index ? 'month active':'month' }>
																		<ul>
																		{items.folder.map(item=>(

																			<li key={item.id} id={item.id} onClick={this.openImages.bind(this, item.month,items.year)}>{item.month}</li>
																			))
																		}
																		</ul>
																	</div>:''
																}
															</div>
														</div>

													))
										      } 	
										  </Slider>
									
											<div className={this.state.isLoaded?"chartImages-block loaded-flex":"chartImages-block"}>
											 {this.state.isLoaded ?
					      						<div className="loader"></div>:
												<div className="row">
												{this.state.chartImages.map((items,i)=>(

													<div key={items.id} className="col-md-3">
														<div className="chartImagesBox">
															<img onClick={this.imageChartShow.bind(this, items.chart_image)}  src={items.chart_image} className='chartImage' />
															<div className="bottom-chart">
															<span className="chart-title">{items.title}</span><span>
															<a href={items.download}>
																<img className='folder-img' src="assets/images/folder.png" />
															</a>
															

															</span>
															</div>
														</div>
													</div>

												))}
													
													

												 </div>
												}
											</div>
									
							
										</div>
									
								</div>
					     

					     	 </div>
					     	
							</div>
						</div>

					</div>

				{/* Demand Chart */}
					<div className="modal fade dynamicPopup" id="live-demand-chart" role="dialog" aria-labelledby="dynamicPopupInvestment" aria-hidden="true">
					 	<div className="modal-dialog  report-popup" role="document">
					    	<div className="modal-content report-popup-content">
					       
				    		<ModalHeader headerImg="assets/images/imgSlider3.png" month={this.state.demandmonth}/>
				     		 <div className="modal-body report-popup-body TradeDemandChart">
				      			<div className='chartIframe'></div>
				      			<div className="row liveTradeData">
					      		 
					      			<div className="col-md-8">
					      		
					      			<Slider {...settingsChart} className='liveTradeData'>
						      	{ this.state.chartDataDemand.map((items,index) => (

						      	
						      			<div key={items.id} style={{width:'121px'}}>

											<div className="box-year">
												<div className={ this.state.newDemandIndex==index ? 'year active':'year' } onClick={this.openDemandMonth.bind(this,index)}>{items.year}</div>
												{this.state.activeYear==true ?
										           
													<div className={ this.state.newDemandIndex==index ? 'month active':'month' }>
														<ul>
														{items.folder.map(item=>(

															<li key={item.id} id={item.id} onClick={this.openImagesDemand.bind(this, item.month,items.year)}>{item.month}</li>
															))
														}
														</ul>
													</div>:''
												}
											</div>
										</div>

									))
						      } 	
						  </Slider>
									
										<div className={this.state.isLoaded?"chartImages-block loaded-flex":"chartImages-block"}>
										 {this.state.isLoaded ?
					      					<div className="loader"></div>:
												<div className="row">
												{this.state.chartImagesDemand.map((items,i)=>(

													<div key={items.id} className="col-md-3">
														<div className="chartImagesBox">
															<img onClick={this.imageChartShow.bind(this, items.chart_image)}  src={items.chart_image} className='chartImage' />
															<div className="bottom-chart">
															<span className="chart-title">{items.title}</span><span>
															<a href={items.download}>
																<img className='folder-img' src="assets/images/folder.png" />
															</a>
															

															</span>
															</div>
														</div>
													</div>

												))}
											
											 </div>
											}
										</div>
									
							
									</div>

								</div>
					     

					     	 </div>
					     	
							</div>
						</div>

					</div>


					<Modal show={this.state.showMoreModal} onHide={this.closeDetailSignal}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					id='SignalModal'
					dialogClassName="faq-content"
					>
							<Modal.Header>
							<div className="filter">
								<Form className='search-Form'>

								<div className="row">

								<div className="col text-center">
									<Form.Group>

									<Form.Control as="select" onChange={e => this.setState({ script_name: e.target.value })} value={this.state.script_name} custom>
									<option value="">All</option>  
									{
									script_name_arr.map((items,i)=>(
									<option value={items.script_name}>{items.script_name}</option>  
									))
									}

									</Form.Control>
									</Form.Group>

								</div>
								<div className="col text-center">
									<Form.Group>

									<Form.Control as="select" onChange={e => this.setState({ buy_sell: e.target.value })} value={this.state.buy_sell}  custom>
									<option value="">All</option>  
									{
									buy_sell_arr.map((items,i)=>(
									<option value={items.buy_sell}>{items.buy_sell}</option>  
									))
									}

									</Form.Control>
									</Form.Group>
								</div>
								<div className="col text-center">
									<DatePicker
									dateFormat="Y-M-dd"
									placeholderText="From Date"
									onChange={this.handleFromDateChange}
									selected={this.state.from}

									/>

								</div>
								<div className="col text-center">
									<DatePicker
									dateFormat="Y-M-dd"
									placeholderText="To Date"
									onChange={this.handleFromToChange}
									selected={this.state.to}
									/>
								</div>
								<div className="col submit-col">
									<Button type="button" className='inputAccount submitSearch form-control' onClick={this.submitSearch} name='searchButton' >Search</Button>
								</div>
								</div>
								</Form>
							</div>
							<Button variant="link" onClick={this.closeDetailSignal} className="close" data-dismiss="modal" aria-label="Close" closeButton>
							<span aria-hidden="true" class="colse-done">Done</span>
							</Button>
							</Modal.Header>
	 
							 <Modal.Body>
							<div id="ShowMoreSignalOption">
							   
						       <div className='daytrade'>
						        {
						        	this.state.isLoaded?
						        	 <div className='loaded-flex' style={{'justify-content':'center', 'align-items':'center','height':'250px'}}><div className='loader'></div></div>:
						      	
						          <div className='row'>
						         
						            {
						             this.state.dayTrade.map((items,i) =>(
						                    <div key={items.id} className="box-gv box-gv-detail col-md-4">
						                      <div>
						                         <div className="gvbtbctc d-flex justify-content-between text-white border border-light">
						                            <div className="date">{items.buy_sell} {items.script_name} {items.id}</div>
						                            <div className="report-val">TRADING QTY : {items.trading_qty} </div>
						                         </div>
						                         <div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
						                            <div className="col-md-12 calc">
						                               <div><span>Entry :{items.t_entry}</span><span className="report-val"> Entry Date : {items.sheet_date}</span></div>
						                               <div><span>Exit :{items.t_exit}</span><span className="report-val"> Exit Date : {items.exit_date}</span></div>
						                             
						                            </div>
						                         </div>
						                        <div className="gvbtbctc calc-box d-flex justify-content-between text-white  border border-light">
						                            
						                            <div className="col-md-12 calc">
						                              <div><span>FINAL CALCULATION</span></div>
						                              <p style={{height:'26px'}}>{items.calculation}</p>
						                            </div>
						                            
						                          </div>

						                           <div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
						                            <div className="col-md-12 calc">
						                               <div><span>Profit :{items.profit_loss}</span><span className="report-val"> Days : {items.days}</span></div>
						                               <div><span>ROI :{items.roi}</span><span className="report-val"> SL : {items.sl}</span></div>
						                             
						                            </div>
						                         </div>
						                        
						                       
						                         <div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
						                           
						                            
						                            <div className="social-icons">
						                            {(items.telegram_entry!=='')? <span>
						                               <a href={items.telegram_entry} target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a>
						                               </span>:''

						                            }
						                            {(items.telegram_exit!=='')?
						                              <span><a href={items.telegram_exit} target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a></span>:''
						                            }
						                              
						                               
						                            </div>

						                             <div className="social-icons">
						                            {(items.twitter_one!=='')? <span>
						                               <a href={items.twitter_one} target="_blank" rel="noreferrer"><i className="fa fa-twitter" aria-hidden="true"></i></a>
						                               </span>:''

						                            }
						                            {(items.twitter_two!=='')?
						                              <span><a href={items.twitter_two} target="_blank" rel="noreferrer"><i className="fa fa-twitter" aria-hidden="true"></i></a></span>:''
						                            }
						                              
						                               
						                            </div>
						                         </div>
						                         </div>
						                      </div>

						                    ))
						                 
						            }
						            </div>
						        }
						        </div>
						     </div>
						     {  this.state.showbutton? 
						      <div className="view-more col-md-12">
								<button className="view-more-btn view-more-signal" onClick={this.showDetailMore}>
									Show More <i class="fa fa-angle-down" aria-hidden="true"></i>
								</button>
							</div>:''
						     }
					  </Modal.Body>

				  {/*<Modal.Footer>
				  		
				  </Modal.Footer>*/}
					 
				</Modal>
		{/*	<div className="modal fade dynamicPopup" id="dynamicPopupDemandChart" role="dialog" aria-labelledby="dynamicPopupInvestment" aria-hidden="true">
			 	<div className="modal-dialog  report-popup" role="document">
			    	<div className="modal-content report-popup-content">
			       
		    		<ModalHeader />
		     		 <div className="modal-body report-popup-body TradeDemandChart">
				        <div id="ShowMoreSignalOption">
						        <div className="filter">
						          <Form className='search-Form'>
						         
						          <div className="row">

						            <div className="col">
						            <Form.Group>
						               
						                <Form.Control as="select" custom>
						                <option value="">All</option>  
						                {
						                  script_name_arr.map((items,i)=>(
						                        <option value={items.script_name}>{items.script_name}</option>  
						                    ))
						                }
						                  
						                </Form.Control>
						              </Form.Group>
						                 
						            </div>
						              <div className="col">
						                   <Form.Group>
						               
						                <Form.Control as="select" custom>
						                <option value="">All</option>  
						                {
						                  buy_sell_arr.map((items,i)=>(
						                        <option value={items.buy_sell}>{items.buy_sell}</option>  
						                    ))
						                }
						                  
						                </Form.Control>
						              </Form.Group>
						            </div>
						            <div className="col">
						              <DatePicker
						              dateFormat="mm/dd/yyyy"
						              onChange={this.handleDateChange}
						              selected={this.state.selected}
						              />
						                  
						            </div>
						            <div className="col">
						              {/*<DatePicker
						              dateFormat="yyyy/MM/dd" 
						               onChange={this.handleDateChange}
						              selected={this.state.selected}
						              />
						            </div>
						            <div className="col">
						                  <Button type="button" className='inputAccount inputSearch form-control' name='searchButton' >Search</Button>
						            </div>
						          </div>
						         </Form>
						        </div>

						       <div className='daytrade'>
						          <div className='row'>
						            {
						             this.state.dayTrade.map((items,i) =>(
						                    <div key={items.id} className="box-gv box-gv-detail col-md-4">
						                      <div>
						                         <div className="gvbtbctc d-flex justify-content-between text-white border border-light">
						                            <div className="date">{items.buy_sell} {items.script_name}</div>
						                            <div className="report-val">TRADING QTY : {items.trading_qty} </div>
						                         </div>
						                         <div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
						                            <div className="col-md-12 calc">
						                               <div><span>Entry :{items.t_entry}</span><span className="report-val"> Entry Date : {items.sheet_date}</span></div>
						                               <div><span>Exit :{items.t_exit}</span><span className="report-val"> Exit Date : {items.exit_date}</span></div>
						                             
						                            </div>
						                         </div>
						                        <div className="gvbtbctc calc-box d-flex justify-content-between text-white  border border-light">
						                            
						                            <div className="col-md-12 calc">
						                              <div><span>FINAL CALCULATION</span></div>
						                              <p style={{height:'26px'}}>{items.calculation}</p>
						                            </div>
						                            
						                          </div>

						                           <div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
						                            <div className="col-md-12 calc">
						                               <div><span>Profit :{items.profit_loss}</span><span className="report-val"> Days : {items.days}</span></div>
						                               <div><span>ROI :{items.roi}</span><span className="report-val"> SL : {items.sl}</span></div>
						                             
						                            </div>
						                         </div>
						                        
						                       
						                         <div className="gvbtbctc d-flex justify-content-between text-white  border border-light">
						                           
						                            
						                            <div className="social-icons">
						                            {(items.telegram_entry!=='')? <span>
						                               <a href={items.telegram_entry} target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a>
						                               </span>:''

						                            }
						                            {(items.telegram_exit!=='')?
						                              <span><a href={items.telegram_exit} target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a></span>:''
						                            }
						                              
						                               
						                            </div>

						                             <div className="social-icons">
						                            {(items.twitter_one!=='')? <span>
						                               <a href={items.twitter_one} target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a>
						                               </span>:''

						                            }
						                            {(items.twitter_two!=='')?
						                              <span><a href={items.twitter_two} target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></span>:''
						                            }
						                              
						                               
						                            </div>
						                         </div>
						                         </div>
						                      </div>

						                    ))
						                 
						            }
						            </div>
						        </div>
						    </div>

				  		</div>
				   </div>
				</div>  

			</div> */}	 
					<SubModal
						screen='singleChartImage'
						headerContent={null}
						bodyContent={this.state.singleImageChart}
					    show={this.state.modalShow}
				        onHide={() => this.setState({ modalShow: false })}
				      />


				  {/*  <SubModal
				     	screen="SignalDetail"
						headerContent={null}
						bodyContent={this.state.dayTrade}
						onChange={this.handleChangeSignal.bind(this)}
						scriptname={this.state.script_name}
						todate={this.state.to}
						fromdate={this.state.from}
						buysell={this.state.buy_sell}
						id={this.state.id}
					    show={this.state.showMoreModal}
				        onHide={() => this.setState({ showMoreModal: false })}
				      />*/}
			</div>
		);
	}
}

export default Footer;