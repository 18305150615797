import React, { Component } from 'react';
import { Form, Button } from "react-bootstrap";
import {Redirect} from 'react-router-dom';
import PrivateHeader from './PrivateHeader';
import '../data/global.js';
import jQuery from 'jquery';

class Support extends Component {
	constructor(props){
		super(props);
		this.state = {
			accountNo:'',
			error:'',
			islogout: false,
			success:'',
			isLoaded:false,
			getTicket:[],
			message:'',
			iscTLoaded:false,
			title:'',
			location:'',
			description:''
		}
		
		 this.get_ticket_list = this.get_ticket_list.bind(this);
		 this.createTicket = this.createTicket.bind(this);
		 this.signOut = this.signOut.bind(this);
	}

	componentDidMount(){
		console.log(performance.navigation.type,"Performance");
		this.get_ticket_list();
		// console.log(sessionStorage.getItem('userIdTokan'),"userIdTokan");
		jQuery(window).on('popstate', function(event) {
		  //  alert("pop");
		   	 window.location.reload(false);
		});
 	}
		
 	signOut = () => {
	    localStorage.clear();
	    this.setState({      islogout: true    });
 	};
 	get_ticket_list(){
 		this.setState({    isLoaded: true   });
 		fetch(global.API+'api/v1/support/get_ticket',{method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ "user_id" : localStorage.getItem('userIdTokan') })})
		.then(res=> res.json())
		.then(
			(result)=>{
			 console.log(result.data.ticket,"getTicket");

				this.setState({
					getTicket:result.data.ticket,
					isLoaded:false
				});
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
 	}

 	enterkeyHandler=(event)=> {
        if (event.keyCode === 13) {
             event.preventDefault();
            this.createTicket();
        }
    }

 	createTicket(){
 		this.setState({    iscTLoaded: true, message:''  });
 		fetch(global.API+'api/v1/support/create-ticket',{method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ "user_id" :localStorage.getItem('userIdTokan'),
        	"title" : this.state.title,
		    "location" : this.state.location,
		    "description" : this.state.description })
    	})
		.then(res=> res.json())
		.then(
			(result)=>{
			 console.log(result.data.ticket,"getTicketCreate");
			 	if(result.status==404){
					console.log(result.message,"resultMessage");
					this.setState({	error:true, success:false,message:result.message,iscTLoaded:false});
				}
				 if(result.status==200){
				
					this.setState({

						error:false,
						success:true,
						message:result.message,
						getTicket:result.data.ticket,
						iscTLoaded:false,
						title:'',
						location:'',
						description:''

					});
				}
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)
 	}

	handleClick(ticket){
           this.props.history.push("/ticketDetail/"+ticket);
     }
	render() {
		 if (this.state.islogout || localStorage.getItem("status")!=='1' ) {
	      return <Redirect to="/login" />;
	    }
		return (
			<div>
				<PrivateHeader logout={this.signOut.bind(this)}/>
			<section className="support-section">
				<div className="container">
				 <div id="top-section" className="support-home">
					<div className="row d-flex justify-content-center">
						<div className='col-md-10'>
							<div className='row support'>
							{
							this.state.iscTLoaded?<div className="loader-fix"><div className="loader"></div></div> :
								<div className='col-md-7 p-0'>
								{
									this.state.error?
										<div className='login-error'>{this.state.message}</div>:''
								}
							
								{
									this.state.success?
									<div className='login-success'>{this.state.message}</div>:''}
										<div className="login-box">
											<h2>Submit a ticket</h2>
												<Form className='login-Form'>
												  <Form.Group >
												  
												    <Form.Control type="text" className='inputTitle ticketInput'  value={this.state.title} onChange={e => this.setState({ title: e.target.value })} onKeyDown={(e) => this.enterkeyHandler(e) }   placeholder="Title" />
												     <Form.Control type="text" className='inputLocation ticketInput'  value={this.state.location} onChange={e => this.setState({ location: e.target.value })} onKeyDown={(e) => this.enterkeyHandler(e) } placeholder="Location" />
												    
												   		<Form.Control as="textarea" className='inputDesc ticketInput' rows={3} value={this.state.description} onChange={e => this.setState({ description: e.target.value })} onKeyDown={(e) => this.enterkeyHandler(e) } placeholder="Description" />
												  </Form.Group>
												  <Button className='submitButton' onClick={this.createTicket} on variant="primary" type="button">
												    Submit
												  </Button>
												</Form>
											 
										</div>
								</div>
							}
								<div className='col-md-5 p-0'>
									<div className='ticket-box'>
										<div className='title-ticket'>Your Ticket</div>
											<div className='list-ticket'>
											{this.state.isLoaded? 	<div className="loader-fix"><div className="loader"></div></div> : 
												<ol className='list-of-ticket'>
												{
													this.state.getTicket.map((items,index) =>(
														<li key={items.id} onClick={this.handleClick.bind(this,items.id)}>
															{items.title}
														</li>
													))
												}
											    </ol>
											}
											</div>
									</div>
									
								</div>
							</div>
						
						</div>
					</div>
				</div>
			</div>
			</section>
		</div>
	
		);
	}
}


export default Support;