import React, { Component } from 'react';
import SubModal from './SubModal';
import Slider from 'react-slick';
import { Modal,Form, Button  } from "react-bootstrap";
import '../data/global.js';

class NFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isLoadedMore: false,
            hover: false,
            faq: [],
            modalShow: false,
            question: '',
            answer: '',
            last_id: '',
            blog: [],
            blogMore: [],
            currentIndex: ''


        };
        // this.onMouseOver = this.onMouseOver.bind(this);
        this.showDetailMore = this.showDetailMore.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoaded: true,
        });

        fetch(global.API + 'api/v1/get_nft')
            .then(res => res.json())
            .then(
                (result) => {
                    //	 console.log(data,"Blog");
                    let arr_length = result.data.length;
                    this.setState({

                        blog: result.data,
                        last_id: result.data[arr_length-1].id,
                        isLoaded: false

                    });
                    this.showDetailMore();
                    //	console.log(data.id,'DATAID');
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )


    }

    showDetailMore(){
        
        this.setState({
            isLoadedMore: true,
        });

        fetch(global.API+'api/v1/get_nft_show_more',{method: 'POST',
        headers: { 
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "last_id" : this.state.last_id,
        })})
        .then(res=> res.json())
        .then(
            (result)=>{
            // console.log(result.data.trade,"TRADE");
             
                //let arr_length = result.data.trade.length;
                this.setState({
                    blogMore:result.data,
                    isLoadedMore:false
                    //id: result.data.trade[arr_length-1].id
                
                });
            },
            (error)=>{
             this.setState({
                isLoadedMore: true,
                error 
              });
            }
        )

    }
    handleSort(qa) {
        this.setState({
            modalShow: true,
            question: qa.question,
            answer: qa.answer
        });
    }
    showImage(index) {
        this.setState({
            hover: true,
            currentIndex: index
        });
    }
    hideImage(index) {
        this.setState({
            hover: false,
            currentIndex: index
        });
    }

    render() {
        return (
            <div>
					<section className="hero-section blog-faq-section ssg-page" id="NFT">
						<div className="container">
							
						  <div id="top-section" className="our-blog-faq pb-5   ">
							
							  <div className="blog-section" >
								<h2>Cryptotrade1's NFT</h2>
								{
								    this.state.isLoaded
                                    ?	
                                    <div className="loader-fix">
                                        <div class="loader">
                                        </div>
                                    </div>:
									<div className="row nft-sec">
								    { this.state.blog.map((items,index)=>(
										
										<div key={items.id} className={(index==2)?"col-md-4 blog-block d-none d-sm-block":"col-md-4 col-12 blog-block"}>
											<div className="card first-card">
										 	    <div className="img-box">

                                                <img className="card-img-top" src={items.image} alt="cryptocurrency image" style={{width:'100%'}} />
                                                
                                                <div className="overlap-img"><img src={items.sub_image} /></div>
                                                </div>
                                                <a href={items.url} target="_blank" className="card-body">
											    	<div className="nft-box">
											     	    <h4 className="card-title">{items.title}</h4>
                                                        <p>{items.description}</p> 
                                                    </div>
												    <div className="read-more-block">
												     	<span><p className="card-text text-dark">View more</p></span> 
												     	<span><img src="assets/images/right-arrow-color.png" alt="Buy now" className="read-more-arrow" /></span>
												      </div>
										        </a>
										    </div>
										</div>
									))}
							        </div>
                                }	
								<div className="view-more view-more-blog ml-0">
									<button className="view-more-btn ml-0" onClick={this.showDetailMore}><a id="#nft-view-more" className="seturlr" data-toggle="modal" data-target="#nft-view-more">View More <img src="assets/images/arrow.png" className="arrow-see-more" /></a></button>
											
								</div>
									
							 </div>
							</div>
						</div>
									
						</section>
                        <div className="modal fade dynamicPopup nft-popup" id="nft-view-more" role="dialog" aria-labelledby="dynamicPopupInvestment" aria-hidden="true">
                            <div className="modal-dialog  report-popup" role="document">
                                <div className="modal-content report-popup-content">
                               
                                    <div className="modal-header report-popup-header">
                                        <div className="header-middle"> <div className='currentMonth'>Cryptotrade1's NFT</div> </div>
                                        
                                        <div className="header-close-btn">
                                            <button type="button" className="close trade-popup-close" data-dismiss="modal" aria-label="Close">
                                             <span aria-hidden="true" className='colse-done'>Done</span> </button>
                                        </div>
                                    </div>
                                    <div className="modal-body report-popup-body TradeDemandChart">
                                        <div className='chartIframe'></div>
                                        <div className="row liveTradeData">
                                        
                                            <div className="col-md-8">
                                        
                                                {
                                                    this.state.isLoadedMore
                                                    ?   
                                                    <div className="loader-fix">
                                                        <div class="loader">
                                                        </div>
                                                    </div>:
                                                    <div className="row nft-sec">
                                                    { this.state.blog.map((items,index)=>(
                                                        
                                                        <div key={items.id} className={(index==2)?"col-md-4 blog-block d-none d-sm-block":"col-md-4 col-6 blog-block"}>
                                                            <div className="card first-card">
                                                                <div className="img-box">

                                                                <img className="card-img-top" src={items.image} alt="cryptocurrency image" style={{width:'100%'}} />
                                                                
                                                                <div className="overlap-img"><img src={items.sub_image} /></div>
                                                                </div>
                                                                
                                                                <a href={items.url} target="_blank" className="card-body">
                                                                    <div className="nft-box">
                                                                        <h4 className="card-title">{items.title}</h4>
                                                                        <p>{items.description}</p>
                                                                            
                                                                    </div>
                                                                    <div className="read-more-block">
                                                                        <span><p className="card-text text-dark">View more</p></span> 
                                                                        <span><img src="assets/images/right-arrow-color.png" alt="Buy now" className="read-more-arrow" /></span>
                                                                      </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    </div>
                                                }   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
			</div>
        );
    }
}

export default NFT;