import React, { Component } from 'react';
import SubModal from './SubModal'
import { Modal, Button, Accordion, Card, Header } from "react-bootstrap";
import '../data/global.js';//global.API+
import {social_media} from '../data/localData';//global.API+

class ContactUs extends Component {
	constructor(props){
		super(props);
		this.state = {
			terms:[],
			modalShow:false,
			isOpen: false
		}
		
		// this.openTerms = this.openTerms.bind(this);
	}		

	componentDidMount(){
		
		fetch(global.API+'api/v1/get_tac')
		.then(res=> res.json())
		.then(
			(result)=>{
			 console.log(result.data.tac,"TAC");
			
				this.setState({
					terms:result.data.tac
				});
			//console.log(tmpSigal,'tmpSigal');
			},
			(error)=>{
			 this.setState({
	            isLoaded: true,
	            error 
	          });
			}
		)


	}
	 openModal = () => this.setState({ isOpen: true });
 	 closeModal = () => this.setState({ isOpen: false });

	render() {
		return (
			<div>
	 <section className="hero-section hireus-section ssg-page" id="ContactUs">

		<div className="container">
			<div id="top-section" className="contactus-block">
															
				<div className="hire-us">
								
					<iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.509052388502!2d33.365529615244824!3d35.16886288031698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de175cf0431da5%3A0x2d3c4267a4d75e66!2sStasinou%2028-A1%2C%20Nicosia%2C%20Cyprus!5e0!3m2!1sen!2sin!4v1623673061903!5m2!1sen!2sin" width="100%" height="100%" style={{border:'0', allowFullscreen:'', loading:'lazy'}}></iframe>				
					<div className="contactus-box">
						<p><a href="https://www.google.com/maps/place/Stasinou+28-A1,+Nicosia,+Cyprus/@35.1688629,33.3655296,17z/data=!4m13!1m7!3m6!1s0x14de175cf0431da5:0x2d3c4267a4d75e66!2sStasinou+28-A1,+Nicosia,+Cyprus!3b1!8m2!3d35.1688629!4d33.3677183!3m4!1s0x14de175cf0431da5:0x2d3c4267a4d75e66!8m2!3d35.1688629!4d33.3677183" target="_blank">28 Stasinou Ave, Flat A1 1060,<br/>Nicosia, Cyprus</a></p>
						<p><a href="mailto:info@cryptotrade1.com" > info@cryptotrade1.com </a></p>
						<p><a href="tel:+3574536987279"> +45-36 98 7279 </a></p>
						<div className="social-media-icons">
							<ul>
							{
								social_media.map((items,index)=>(
									<li key={items.id}>
											<a href={items.url} target="_blank">
												<img src={items.image} alt={items.name} />
											</a>
										</li>	
									))
							}
								
								{/*<li>
									<a href="https://www.facebook.com/CryptoTrade1-156886548258635/" target="_blank">
										<img src="assets/images/facebook.png" alt="Facebook" />
									</a>
								</li>
								
								<li>
									<a href="https://t.me/cctrade1" target="_blank">
										<img src="assets/images/telegram.png" alt="telegram" />
									</a>
								</li>
								<li>
									<a href="https://twitter.com/cctrade11" target="_blank">
										<img src="assets/images/twitter.png" alt="twitter" />
									</a>
								</li>
								<li>
									<a href="https://wa.me/971529920903" target="_blank">
										<img src="assets/images/whatsapp.png" alt="whatsapp" />
									</a>
								</li>
								*/}

							</ul>
							{/*<div className="text-center"><button className="contactus">Contact Us</button></div>*/}

						</div>
						
						<div onClick={this.openModal} className="terms-contact d-block">Terms and Conditions Risk Discloser Statement</div>
						 
					</div>
				</div>
				

			</div>
		</div>
			
		<Modal className='terms-contact-modal' show={this.state.isOpen} onHide={this.closeModal}>
         
          <Modal.Header>
            <Modal.Title>Terms and Conditions Risk Discloser Statement</Modal.Title>
              <Button variant="link" onClick={this.closeModal} className="close" data-dismiss="modal" aria-label="Close" closeButton>
		          <span aria-hidden="true" class="colse-done">Done</span>
		        </Button>
          </Modal.Header>
         	 <Modal.Body>

         
         	 <Accordion defaultActiveKey="4" className='terms_conditions'>
         	 {
         	 this.state.terms.map((item, index) => (

         	 	   <Card key={item.id}>
				    <Card.Header>
				      <Accordion.Toggle as={Button} variant="link" eventKey={item.id}>
				      {item.question}
				      </Accordion.Toggle>
				    </Card.Header>
				    <Accordion.Collapse eventKey={item.id}>
				      <Card.Body> <div dangerouslySetInnerHTML={{__html: item.answer}}></div>  </Card.Body>
				    </Accordion.Collapse>
				  </Card>
         	 	))
         	 }
         	 </Accordion>
         	 	</Modal.Body>
          	 <Modal.Footer>
          
          </Modal.Footer>

        </Modal>

	</section>

	</div>
	);
	}
}

export default ContactUs;
