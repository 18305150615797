import React, { Component } from 'react';
import {Link} from "react-router-dom";
// import tradeLogo from '/assets/images/logo.png';
function refreshPage() {
	window.location.reload(false);
}

class Header extends Component {
	render() {
		return (
			<div className='Header-part'>

				<div className="main-nav">
					<div className="container-fluid">
						<header>
							<div className="header-section">
								<div className="logo">
									<a  href="#" data-link-name="Home">
										<img src="assets/images/logo-new.png" alt="logo" className="header-logo" />
									</a>
								</div>
								<div className="page-header-btn play-btn-div text-center" style={{display: "none"}}>
									<div className="see-more">
										<button className="play-btn videos">Live <sup>.</sup> <img src="assets/images/play-btn.png" className="play-btn-img" /></button>
										
									</div>	
								</div>
								<div className="page-header-btn talk-btn-div text-center" style={{display: "none"}}>
									<div className="see-more">
									<button className="play-btn talkus">Talk Us <img src="assets/images/chat.png" className="play-btn-img" /></button>
										
									</div>	
								</div>
								<div className="page-header-btn contactus-btn-div text-center" style={{display: "none"}}>
									<div className="see-more">
									<button className="play-btn contact-btn">Contact Us <img src="assets/images/contactus.png" className="contact-btn-img" /></button>
										
									</div>	
								</div>
								<div className="toggel-btn-area">
									<button className="toggel-btn">
										<img src="assets/images/toggle-btn.png" alt="toggel-btn" className="toggel-btn-img" />
									</button>
									
								</div>	
							</div>
						</header>
					</div>
					<div className="sidenav">
					
					<ul className="bar">
					<li className="active"> <span className="pageName">Home</span><a href="#home" className="dot-link" data-link-name="Home">01</a></li>
					<li><span className="liveperMenu">Live Performance</span><a href="#Performance"  className="dot-link" data-link-name="Live Performance">02</a></li>
					<li><span className="liveperMenu">Copytrade</span><a href="#CopytradePerformance"  className="dot-link" data-link-name="Copytrade Performance">03</a></li>
					<li> <span className="videosMenu">Videos</span> <a href="#Video" className="dot-link" data-link-name="Videos">04</a></li>
					<li> <span>Products</span> <a href="#Products" className="dot-link" data-link-name="Products">05</a></li>
					<li> <span>NFT</span> <a href="#NFT" className="dot-link" data-link-name="NFT">06</a></li>
					
					<li> <span className="blogfaqMenu">Blog & FAQ</span><a href="#BlogFAQ" className="dot-link" data-link-name="Blog & FAQ">07</a></li>
					{/*<li> <span className="telegramMenu">Telegram</span><a href="#Telegram" className="dot-link" data-link-name="Telegram">07</a></li>*/}
					<li> <span className="contactusMenu">Contact Us</span><a href="#ContactUs" className="dot-link" data-link-name="Contact Us">08</a></li>
					</ul>

					</div>

					<div id="toggleSidenav" className="toggleNav">
							<div className="row container-fluid m-auto">
								<div className="col col-sm-6 col-md-6 text-left logo">
									<a href="#">
										<img src="assets/images/logo-new.png" alt="logo" className="header-logo" />
									</a>
								</div>
								<div className="col col-sm-6 col-md-6">
									<button className="closebtn" style={{display: "none"}} >&times;</button>	
								</div>
								
							</div>
					  
							<div className="row h-md-100 justify-content-center align-items-center">

								<div className="col-md-7 d-none d-lg-block toggle-menu-block">
									<img src="assets/images/menuImage-123.png" alt="Stock Image" className="toggleImage" />
								</div>
								<div className="col-md-5 toggleSlideMenu">
								  	<ul>
								  		{/*<li><Link data-link-name="Home" to="/">Home</Link></li>*/}
								  		<li><a href="#home" data-link-name="Home" className="home active">Home</a>	</li>
								  		<li><a href="#Performance" className="" data-link-name="Live Performance"> Performance</a></li>
								  		<li><a href="#CopytradePerformance" className="" data-link-name="Copytrade Performance"> Copytrade</a></li>
								  		<li><a href="#Video" className="" data-link-name="Videos">Video</a></li>
								  		<li><a href="#Products" className="" data-link-name="Products">Products</a></li>
								  		<li><a href="#NFT" className="" data-link-name="NFT">NFT</a></li>
								  		<li><a href="#BlogFAQ" className="" data-link-name="Blog & FAQ">Blog & FAQ</a></li>
								  		{/*<li><a href="#Telegram" className="" data-link-name="Telegram">Telegram</a></li>*/}
								  		<li><a href="#ContactForm" className="" data-link-name="ContactForm">Payment</a></li>
								  		<li><a href="#ContactUs" className="" data-link-name="Contact Us">Contact Us</a></li>
								  		
								  		<li><Link data-link-name="login" to="/login">Support</Link></li>
								  	</ul>

								</div>
							</div>

				 
					</div>

					
						<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
						  <div className="modal-dialog modal-dialog-centered" role="document">
						    <div className="modal-content">
						     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
						          <span aria-hidden="true"><img src="assets/images/rejected.png" /></span>
						        </button>
						      <div className="modal-header">
						        <h5 className="modal-title" id="exampleModalCenterTitle">See All Telegram Channels</h5>
						      </div>
						      <div className="modal-body">
						     	<img src="assets/images/teliram_image.png" alt="Teligram Channels" className="teligramChennels" />
						      </div>
						      <div className="modal-footer">
						        
						        <button type="button" className="btn btn-primary teligram-button">
						        <img src="assets/images/touch.png" className='teli-btn-img' />Click Here</button>
						      </div>
						    </div>
						  </div>
						</div>

				
					
					
				</div>
					
			</div>
		);
	}
}

export default Header;