import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

function refreshPage() {
    window.location.reload(false);
}

const PrivateHeader = (props) => {



    return (

        <div className='Header-part private-header-part'>

				<div className="main-nav">
					<div className="container-fluid">
						<header className='private-header'>
							<div className="header-section">
								<div className="logo">
									<a  href="#" data-link-name="Home">
										<img src="../assets/images/cryptologo_small.png" alt="logo" className="header-logo" />
									</a>
								</div>
								<div className='support-title'>
									<h2>Support</h2>
								</div>
								<div className="toggel-btn-area">
									{/*<button className="toggel-btn">
										<img src="assets/images/toggle-btn.png" alt="toggel-btn" className="toggel-btn-img" />
									</button>*/}

									{/*<ul>
										<li><a href="/" onClick={refreshPage} data-link-name="Home" className="home active">Home</a>	</li>
										<li><Link  onClick={props.logout} >logout</Link></li>	
									</ul>*/}
									{ localStorage.length>0 && localStorage.getItem('userIdTokan')!=='' ?
									<Dropdown>
										  <Dropdown.Toggle variant="Primary" id="dropdown-basic">
										{ localStorage.length>0 && localStorage.getItem('userIdTokan')!=='' ?  'Welcome, '+localStorage.getItem('name'):''}
										  </Dropdown.Toggle>

										  <Dropdown.Menu className='right-toggle-menu'>
										    <Dropdown.Item href="/" className='right-menu-home user-menu'>Home</Dropdown.Item>
										    {
										    		localStorage.length>0?
										   	<div>
										    <Dropdown.Item href="/support" className='user-menu'> Create Ticket</Dropdown.Item>
										    <Dropdown.Item className='user-menu' onClick={props.logout}>Logout</Dropdown.Item>
										 	 </div>:''	
										    }
										    </Dropdown.Menu>
									</Dropdown>:
									<ul className='public-user-menu'>
										<li><a href="/" onClick={refreshPage} data-link-name="Home" className="home-link active">Home</a>	</li>
										
									</ul>
								}
								</div>

							</div>
						</header>
					</div>
					

					<div id="toggleSidenav" className="toggleNav">
							<div className="row container-fluid m-auto">
								<div className="col col-sm-6 col-md-6 text-left">
									<a href="index.html">
										<img src="assets/images/logo.png" alt="logo" className="header-logo" />
									</a>
								</div>
								<div className="col col-sm-6 col-md-6">
									<a href="#" className="closebtn" style={{display: "none"}} >&times;</a>	
								</div>
								
							</div>
					  
							<div className="row h-md-100 justify-content-center align-items-center">

								<div className="col-md-7 d-none d-lg-block">
									<img src="assets/images/menuSliderImage-min.png" alt="Stock Image" className="toggleImage" />
								</div>
								 <div className="col-md-5 toggleSlideMenu">
								  		<ul>
								  		{/*<li><Link data-link-name="Home" to="/">Home</Link></li>*/}
								  		<li><a href="/" onClick={refreshPage} data-link-name="Home" className="home active">Home</a>	</li>
								  		
								  		<li><Link data-link-name="Support" to="/login">Support</Link></li>
								  		<li><Link data-link-name="Support" to="/login">login</Link></li>

								  	
								  	</ul>

								</div>
							</div>

				 
					</div>

					
					
					
				</div>
					
			</div>

    )
}

export default PrivateHeader;