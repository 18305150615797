import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './Home';
import Login from './components/Login';
import SupportHome from './components/SupportHome';
import Support from './components/Support';
import ticketDetail from './components/ticketDetail';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

function App() {

const fullPath = window.location.pathname;
// console.log(fullPath,"FULLPATH");
  return (
    <div className="App">
    
	 <Router>

		  
		    <Switch>
		       <Route exact path="/" component={Home} />
		        <Route path="/login" component={Login} />
		        <Route path="/support" component={Support} />
		        <Route path="/ticketDetail/:id" component={ticketDetail} />
		        <Route path='/blog' component={() => { 
     				window.location.href = 'https://blog.cryptotrade1.com/'+fullPath; 
     				return null;
						}}/>
		    </Switch>

		  

		</Router>
  </div>
  );
}

export default App;
